<template>
  <div class="bg-white p-6 rounded-lg shadow-md">
    <ConfirmDialog />
    
    <div class="flex justify-start mb-4">
      <Button 
        label="New user" 
        icon="fa-solid fa-user-plus" 
        class="bg-blue-900 hover:bg-blue-800 text-white border-blue-900 py-3 px-6"
        @click="handleNewUser" 
      />
    </div>
    
    <div class="card">
      <DataTable 
        :value="users" 
        :paginator="true" 
        :rows="10"
        :rowsPerPageOptions="[5, 10, 20, 50]"
        filterDisplay="menu"
        :globalFilterFields="['id', 'name', 'email', 'role']"
        v-model:filters="filters"
        v-model:selection="selectedUsers"
        dataKey="id"
        :loading="loading"
        class="p-datatable-sm"
      >
        <template #header>
          <div class="flex justify-between items-center">
            <span class="p-input-icon-left">
              <i class="pi pi-search" />
              <InputText v-model="filters['global'].value" placeholder="Search users..." class="p-3" />
            </span>
            <div>
              <Button label="Refresh" icon="pi pi-refresh" class="p-button-outlined" @click="loadUsers" />
            </div>
          </div>
        </template>

        <Column selectionMode="multiple" headerStyle="width: 3rem"></Column>
        <Column field="id" header="ID" sortable></Column>
        <Column field="name" header="Name" sortable></Column>
        <Column field="email" header="Email" sortable></Column>
        <Column field="role" header="Role" sortable></Column>
        <Column field="created_at" header="Created At" sortable>
          <template #body="{ data }">
            {{ new Date(data.created_at).toLocaleDateString() }}
          </template>
        </Column>
        <Column header="Actions" :style="{ width: '5rem' }">
          <template #body="{ data }">
            <Menu ref="menu" :model="getActionItems(data)" :popup="true" />
            <Button 
              icon="pi pi-ellipsis-h" 
              class="p-button-rounded p-button-text p-button-plain" 
              @click="(event) => toggleMenu(event, data.id)"
              aria-label="Options"
            />
          </template>
        </Column>
      </DataTable>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, reactive } from 'vue'
import { FilterMatchMode } from '@primevue/core/api'
import { useRouter } from 'vue-router'
import { useAuthStore } from '../store/auth'
import { useConfirm } from 'primevue/useconfirm'
import { useToast } from 'vue-toastification'
import { userService } from '../utils/userService'

// PrimeVue components
import DataTable from 'primevue/datatable'
import Column from 'primevue/column'
import Button from 'primevue/button'
import InputText from 'primevue/inputtext'
import ConfirmDialog from 'primevue/confirmdialog'
import Menu from 'primevue/menu'

const router = useRouter()
const authStore = useAuthStore()
const users = ref([])
const selectedUsers = ref([])
const loading = ref(true)
const toast = useToast()
const confirm = useConfirm()
const menu = ref() // Reference to the Menu component

// Initialize filters
const filters = ref({
  global: { value: null, matchMode: FilterMatchMode.CONTAINS }
})

// Load users from API
const loadUsers = async () => {
  loading.value = true
  
  try {
    const data = await userService.getUsers()
    
    // Map the data to the expected format
    users.value = data.users.map(user => ({
      id: user.id,
      name: user.full_name,
      email: user.email,
      role: user.role || 'User', // Default to User if role is not set
      created_at: user.created_at,
      first_name: user.first_name,
      last_name: user.last_name,
      phone_number: user.phone_number
    }))
  } catch (error) {
    console.error('Error loading users:', error)
    toast.error('Failed to load users')
  } finally {
    loading.value = false
  }
}

// Toggle dropdown menu
const toggleMenu = (event, id) => {
  menu.value.toggle(event)
}

// Get action items for dropdown menu
const getActionItems = (user) => {
  return [
    {
      label: 'Edit User',
      icon: 'pi pi-pencil',
      command: () => handleEditUser(user)
    },
    {
      label: 'Delete User',
      icon: 'pi pi-trash',
      className: 'p-error',
      command: () => confirmDeleteUser(user)
    }
  ]
}

const handleNewUser = () => {
  // Redirect to register form
  router.push('/register')
}

const handleEditUser = (user) => {
  // Store the user data temporarily to use in the edit form
  localStorage.setItem('editingUser', JSON.stringify(user))
  
  // Redirect to edit form
  router.push(`/register?edit=${user.id}`)
}

const confirmDeleteUser = (user) => {
  confirm.require({
    message: `Are you sure you want to delete ${user.name}?`,
    header: 'Delete Confirmation',
    icon: 'pi pi-exclamation-triangle',
    acceptButtonProps: { class: 'p-button-danger' },
    accept: () => deleteUser(user),
    reject: () => {}
  })
}

const deleteUser = async (user) => {
  try {
    loading.value = true
    
    const data = await userService.deleteUser(user.id)
    
    if (data.status === 'success') {
      toast.success('User deleted successfully')
      // Reload the user list
      loadUsers()
    } else {
      toast.error(data.message || 'Failed to delete user')
    }
  } catch (error) {
    console.error('Error deleting user:', error)
    toast.error('An error occurred while deleting the user')
  } finally {
    loading.value = false
  }
}

onMounted(() => {
  // Check if user is authenticated before loading data
  if (authStore.isAuthenticated) {
    loadUsers()
  } else {
    router.push('/login')
  }
})
</script>

<style scoped>
.p-input-icon-left {
  padding: 0.5rem 0;
}

.p-button {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

/* Menu styling */
:deep(.p-menu) {
  width: 200px;
}

:deep(.p-menu .p-menuitem-link) {
  padding: 0.75rem 1rem;
}

:deep(.p-menu .p-menuitem-link .p-menuitem-icon) {
  margin-right: 0.75rem;
}

:deep(.p-menu .p-menuitem-link .p-menuitem-text) {
  font-size: 0.9rem;
}

:deep(.p-error .p-menuitem-text, .p-error .p-menuitem-icon) {
  color: #ef4444;
}
</style>