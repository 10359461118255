<template>
  <div class="bg-white p-6 rounded-lg shadow-md">
    <ConfirmDialog />
    
    <div class="flex justify-start mb-4">
      <SignupLinkDialog   @success="loadSignupLinks" />
    </div>
    
    <div class="card">
      <DataTable 
        :value="signupLinks" 
        :paginator="true" 
        :rows="10"
        :rowsPerPageOptions="[5, 10, 20, 50]"
        filterDisplay="menu"
        :globalFilterFields="['id', 'email', 'status']"
        v-model:filters="filters"
        v-model:selection="selectedLinks"
        dataKey="id"
        :loading="loading"
        class="p-datatable-sm"
      >
        <template #header>
          <div class="flex justify-between items-center">
            <span class="p-input-icon-left">
              <i class="fa-solid fa-magnifying-glass" />
              <InputText v-model="filters['global'].value" placeholder="Search links..." class="p-3" />
            </span>
            <div>
              <Button label="Refresh" icon="pi pi-refresh" class="p-button-outlined" @click="loadSignupLinks" />
            </div>
          </div>
        </template>

        <Column field="id" header="ID" sortable></Column>
        <Column field="email" header="Email" sortable></Column>
        <Column field="status" header="Status" sortable>
          <template #body="{ data }">
            <Tag 
              :value="data.status" 
              :severity="getStatusSeverity(data.status)" 
            />
          </template>
        </Column>
        <Column field="visited" header="Viewed" sortable>
          <template #body="{ data }">
            <i :class="data.visited ? 'pi pi-check text-green-500' : 'pi pi-times text-red-500'"></i>
          </template>
        </Column>
        <Column field="completed" header="Completed" sortable>
          <template #body="{ data }">
            <i :class="data.completed ? 'pi pi-check text-green-500' : 'pi pi-times text-red-500'"></i>
          </template>
        </Column>
        <Column field="expires_at" header="Expires" sortable>
          <template #body="{ data }">
            {{ formatDate(data.expires_at) }}
          </template>
        </Column>
        <Column field="created_at" header="Created" sortable>
          <template #body="{ data }">
            {{ formatDate(data.created_at) }}
          </template>
        </Column>
        <Column header="Actions" :style="{ width: '5rem' }">
          <template #body="{ data }">
            <Menu ref="menu" :model="getActionItems(data)" :popup="true" />
            <Button 
              icon="pi pi-ellipsis-h" 
              class="p-button-rounded p-button-text p-button-plain" 
              @click="(event) => toggleMenu(event, data.id)"
              aria-label="Options"
            />
          </template>
        </Column>
      </DataTable>
    </div>
  </div>

</template>

<script setup>
import { ref, onMounted, reactive, computed } from 'vue'
import { FilterMatchMode } from '@primevue/core/api'
import { useRouter } from 'vue-router'
import { useAuthStore } from '../store/auth'
import { useConfirm } from 'primevue/useconfirm'
import { useToast } from 'vue-toastification'
import signupLinkService from '../utils/signupLinkService'

// Import the custom dialog component
import SignupLinkDialog from '../components/SignupLinkDialog.vue'

// PrimeVue components
import DataTable from 'primevue/datatable'
import Column from 'primevue/column'
import Button from 'primevue/button'
import InputText from 'primevue/inputtext'
import ConfirmDialog from 'primevue/confirmdialog'
import Tag from 'primevue/tag'
import Menu from 'primevue/menu'

const router = useRouter()
const authStore = useAuthStore()
const signupLinks = ref([])
const selectedLinks = ref([])
const loading = ref(true)
const toast = useToast()
const confirm = useConfirm()
const menu = ref() // Reference to the Menu component

// Initialize filters
const filters = ref({
  global: { value: null, matchMode: FilterMatchMode.CONTAINS }
})

// Format date for display
const formatDate = (dateString) => {
  if (!dateString) return 'N/A'
  const date = new Date(dateString)
  return date.toLocaleDateString() + ' ' + date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })
}

// Toggle dropdown menu
const toggleMenu = (event, id) => {
  menu.value.toggle(event)
}

// Get action items for dropdown menu
const getActionItems = (link) => {
  const items = []
  
  // Only show resend option for active, incomplete links
  if (link.active && !link.completed) {
    items.push({
      label: 'Resend Email',
      icon: 'pi pi-envelope',
      command: () => handleResendLink(link)
    })
  }
  
  // Delete option is always available
  items.push({
    label: 'Delete Link',
    icon: 'pi pi-trash',
    className: 'p-error',
    command: () => confirmDeleteLink(link)
  })
  
  return items
}

// Get status severity for PrimeVue Tag component
const getStatusSeverity = (status) => {
  switch (status) {
    case 'Active': return 'success'
    case 'Completed': return 'info'
    case 'Expired': return 'warning'
    default: return 'info'
  }
}

// Load signup links from API
const loadSignupLinks = async () => {
  loading.value = true
  
  try {
    const response = await signupLinkService.getSignupLinks()
    const data = response.data
    
    // Map the data to the expected format
    signupLinks.value = data.signup_links.map(link => ({
      id: link.id,
      email: link.email,
      token: link.token,
      visited: link.visited,
      completed: link.completed,
      expires_at: link.expires_at,
      created_at: link.created_at,
      active: link.active,
      status: link.completed ? 'Completed' : (link.active ? 'Active' : 'Expired')
    }))
  } catch (error) {
    console.error('Error loading signup links:', error)
    toast.error('Failed to load signup links')
  } finally {
    loading.value = false
  }
}


// Resend a signup link email
const handleResendLink = async (link) => {
  try {
    loading.value = true
    const response = await signupLinkService.resendSignupLink(link.id)
    const data = response.data
    
    if (data.status === 'success') {
      toast.success('Signup link resent successfully')
      loadSignupLinks()
    } else {
      toast.error(data.message || 'Failed to resend signup link')
    }
  } catch (error) {
    console.error('Error resending signup link:', error)
    toast.error('An error occurred while resending the signup link')
  } finally {
    loading.value = false
  }
}

// Delete a signup link
const confirmDeleteLink = (link) => {
  confirm.require({
    message: `Are you sure you want to delete the signup link for ${link.email}?`,
    header: 'Delete Confirmation',
    icon: 'pi pi-exclamation-triangle',
    acceptButtonProps: { class: 'p-button-danger' },
    accept: () => deleteSignupLink(link),
    reject: () => {}
  })
}

const deleteSignupLink = async (link) => {
  try {
    loading.value = true
    
    const response = await signupLinkService.deleteSignupLink(link.id)
    const data = response.data
    
    if (data.status === 'success') {
      toast.success('Signup link deleted successfully')
      loadSignupLinks()
    } else {
      toast.error(data.message || 'Failed to delete signup link')
    }
  } catch (error) {
    console.error('Error deleting signup link:', error)
    toast.error('An error occurred while deleting the signup link')
  } finally {
    loading.value = false
  }
}

onMounted(() => {
  // Check if user is authenticated before loading data
  if (authStore.isAuthenticated) {
    loadSignupLinks()
  } else {
    router.push('/login')
  }
})
</script>

<style scoped>
.p-tag {
  min-width: 80px;
  justify-content: center;
}

.p-input-icon-left {
  padding: 0.5rem 0;
}

.p-button {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

/* Style action buttons */
.p-button-rounded.p-button-text {
  padding: 0.5rem !important;
}

/* Menu styling */
:deep(.p-menu) {
  width: 200px;
}

:deep(.p-menu .p-menuitem-link) {
  padding: 0.75rem 1rem;
}

:deep(.p-menu .p-menuitem-link .p-menuitem-icon) {
  margin-right: 0.75rem;
}

:deep(.p-menu .p-menuitem-link .p-menuitem-text) {
  font-size: 0.9rem;
}

:deep(.p-error .p-menuitem-text, .p-error .p-menuitem-icon) {
  color: #ef4444;
}
</style>