<template>
  <div class="bg-white p-6 rounded-lg shadow-md">
    <h2 class="text-2xl font-bold mb-6">Your Profile</h2>
    
    <div class="mb-6">
      <div class="mb-4">
        <h3 class="text-xl font-bold mb-2">Account Information</h3>
        <p><span class="font-semibold">Name:</span> {{ user?.first_name }} {{ user?.last_name }}</p>
        <p><span class="font-semibold">Email:</span> {{ user?.email }}</p>
        <p><span class="font-semibold">Phone:</span> {{ user?.phone_number || 'Not provided' }}</p>
      </div>
      
      <div class="mb-6">
        <h3 class="text-xl font-bold mb-2">Two-Factor Authentication</h3>
        <p v-if="user?.otp_enabled" class="text-green-600 font-semibold mb-2">
          ✓ 2FA is currently enabled ({{ get2FAMethodLabel }})
        </p>
        <p v-else class="text-yellow-600 font-semibold mb-2">
          ✗ 2FA is not enabled
        </p>
        
        <div v-if="user?.otp_enabled" class="mt-4 mb-4">
          <h4 class="font-semibold text-gray-700 mb-2">2FA Method</h4>
          <div class="flex items-center space-x-4">
            <label class="inline-flex items-center">
              <input 
                type="radio" 
                name="2fa_method" 
                value="sms" 
                :checked="user?.two_factor_method === 'sms'"
                @change="update2FAMethod('sms')"
                class="form-radio h-4 w-4 text-blue-600"
              >
              <span class="ml-2">SMS</span>
            </label>
            <label class="inline-flex items-center">
              <input 
                type="radio" 
                name="2fa_method"
                value="app" 
                :checked="user?.two_factor_method === 'app'"
                @change="update2FAMethod('app')"
                class="form-radio h-4 w-4 text-blue-600"
              >
              <span class="ml-2">Authenticator App</span>
            </label>
          </div>
          <p class="text-sm text-gray-500 mt-1">
            {{ user?.two_factor_method === 'sms' ? 
              'Verification codes will be sent to your phone number.' : 
              'Use an authenticator app like Google Authenticator or Authy.' 
            }}
          </p>
        </div>
        
        <div class="mt-4">
          <button 
            v-if="!user?.otp_enabled"
            @click="setupTwoFactor"
            class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline mr-2"
          >
            Setup 2FA
          </button>
          <button 
            v-else
            @click="disableTwoFactor"
            class="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
            :disabled="authStore.loading"
          >
            {{ authStore.loading ? 'Disabling...' : 'Disable 2FA' }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, ref } from 'vue'
import { useRouter } from 'vue-router'
import { useAuthStore } from '../../store/auth'

const router = useRouter()
const authStore = useAuthStore()
const user = computed(() => authStore.currentUser)
const updating2FAMethod = ref(false)

const get2FAMethodLabel = computed(() => {
  if (!user.value) return ''
  
  return user.value.two_factor_method === 'sms' ? 'SMS' : 'Authenticator App'
})

const setupTwoFactor = () => {
  router.push('/setup-2fa')
}

const update2FAMethod = async (method) => {
  if (updating2FAMethod.value) return
  
  updating2FAMethod.value = true
  try {
    await authStore.update2FAMethod(method)
    await authStore.fetchCurrentUser()
  } catch (error) {
    // Error is handled in the store
  } finally {
    updating2FAMethod.value = false
  }
}

const disableTwoFactor = async () => {
  try {
    await authStore.disable2FA()
    await authStore.fetchCurrentUser()
  } catch (error) {
    // Error is handled in the store
  }
}
</script>