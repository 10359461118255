<template>
  <div class="bg-white p-6 rounded-lg shadow-md">
    <h2 class="text-2xl font-bold mb-4">Welcome to ALLDOQ Ground Control</h2>
    
    <div v-if="authStore.isAuthenticated">
      <p class="mb-4">Hello, {{ authStore.currentUser?.first_name }}! You are logged in.</p>
      <p>
        <router-link 
          to="/profile" 
          class="text-blue-600 hover:text-blue-800 underline"
        >
          Go to your profile
        </router-link>
      </p>
    </div>
    
    <div v-else>
      <p class="mb-4">Please log in or create an account to get started.</p>
      <div class="flex space-x-4">
        <router-link 
          to="/login" 
          class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
        >
          Login
        </router-link>
        <router-link 
          to="/register" 
          class="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded"
        >
          Register
        </router-link>
      </div>
    </div>
  </div>
</template>

<script setup>
import { useAuthStore } from '../store/auth'

const authStore = useAuthStore()
</script>