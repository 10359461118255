<template>
  <div class="bg-white p-6 rounded-lg shadow-md">
    <h2 class="text-2xl font-bold mb-6">Setup Two-Factor Authentication</h2>
    
    <div v-if="loading" class="text-center my-8">
      <p class="text-lg mb-2">Loading...</p>
    </div>
    
    <template v-else>
      <div class="mb-6">
        <h3 class="text-xl font-semibold mb-4">Choose 2FA Method</h3>
        
        <div class="flex flex-col space-y-4 mb-6">
          <label class="inline-flex items-center">
            <input 
              type="radio" 
              name="2fa_method" 
              value="sms" 
              v-model="selectedMethod"
              class="form-radio h-4 w-4 text-blue-600"
            >
            <span class="ml-2">SMS Verification (Recommended)</span>
          </label>
          <label class="inline-flex items-center">
            <input 
              type="radio" 
              name="2fa_method"
              value="app" 
              v-model="selectedMethod"
              class="form-radio h-4 w-4 text-blue-600"
            >
            <span class="ml-2">Authenticator App</span>
          </label>
        </div>
        
        <!-- SMS Setup -->
        <div v-if="selectedMethod === 'sms'" class="mb-6">
          <p class="mb-4">
            Verification codes will be sent to your phone number: <strong>{{ currentUser?.phone_number }}</strong>
          </p>
          
          <p class="text-sm text-gray-600 mb-4">
            Make sure your phone number is correct in your profile settings.
          </p>
          
          <button 
            @click="requestSmsCode" 
            class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline mr-2"
            :disabled="authStore.loading || requestingCode"
          >
            {{ requestingCode ? 'Sending...' : 'Send Code' }}
          </button>
          
          <p v-if="codeSent" class="text-green-500 text-sm mt-2">
            Verification code has been sent!
          </p>
        </div>
        
        <!-- App Setup -->
        <div v-if="selectedMethod === 'app'" class="mb-6">
          <p class="mb-4">
            Scan the QR code below with your authenticator app (such as Google Authenticator, 
            Authy, or Microsoft Authenticator).
          </p>
          
          <div class="flex justify-center my-6" v-html="qrCode"></div>
          
          <div class="mb-6">
            <p class="mb-2 font-semibold">Manual entry code:</p>
            <p class="font-mono bg-gray-100 p-2 rounded select-all">{{ secret }}</p>
          </div>
        </div>
      </div>
      
      <form @submit.prevent="enableTwoFactor" class="mt-6">
        <div class="mb-6">
          <label for="otp_code" class="block text-gray-700 text-sm font-bold mb-2">Verification Code</label>
          <input 
            type="text" 
            id="otp_code" 
            v-model="otpCode" 
            required
            class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            placeholder="Enter 6-digit code"
            maxlength="6"
            pattern="[0-9]{6}"
            inputmode="numeric"
          />
          <p class="text-gray-600 text-sm mt-1">
            Enter the code {{ selectedMethod === 'sms' ? 'sent to your phone' : 'shown in your authenticator app' }} to verify setup
          </p>
        </div>
        
        <div class="flex items-center justify-between">
          <button 
            type="submit" 
            class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
            :disabled="authStore.loading"
          >
            {{ authStore.loading ? 'Verifying...' : 'Enable 2FA' }}
          </button>
          <router-link 
            to="/profile" 
            class="inline-block align-baseline font-bold text-sm text-red-500 hover:text-red-800"
          >
            Cancel
          </router-link>
        </div>
      </form>
    </template>
  </div>
</template>

<script setup>
import { ref, onMounted, computed } from 'vue'
import { useRouter } from 'vue-router'
import { useAuthStore } from '../../store/auth'

const router = useRouter()
const authStore = useAuthStore()
const otpCode = ref('')
const qrCode = ref('')
const secret = ref('')
const loading = ref(true)
const selectedMethod = ref('sms') // Default to SMS as requested
const requestingCode = ref(false)
const codeSent = ref(false)
const currentUser = computed(() => authStore.currentUser)

onMounted(async () => {
  try {
    const result = await authStore.setup2FA()
    qrCode.value = result.qr_code
    secret.value = result.secret
    
    // If the API returns a preferred method, use it
    if (result.two_factor_method) {
      selectedMethod.value = result.two_factor_method
    }
  } catch (error) {
    // Error is handled in the store
  } finally {
    loading.value = false
  }
})

const requestSmsCode = async () => {
  requestingCode.value = true
  codeSent.value = false
  
  try {
    // This will call the request_sms_code endpoint
    const result = await authStore.requestSmsCode()
    if (result.status === 'success') {
      codeSent.value = true
    }
  } catch (error) {
    // Error is handled in the store
  } finally {
    requestingCode.value = false
  }
}

const enableTwoFactor = async () => {
  try {
    // Update the 2FA method first
    await authStore.update2FAMethod(selectedMethod.value)
    
    // Then enable 2FA
    await authStore.enable2FA(otpCode.value)
    await authStore.fetchCurrentUser()
    router.push('/profile')
  } catch (error) {
    // Error is handled in the store
  }
}
</script>