<template>
  <div class="instance-details p-4 bg-gray-50 rounded-lg shadow-inner">
    <h2 class="text-xl font-bold mb-4">Instance details</h2>
    
    <!-- Instance URL Banner -->
    <div v-if="instanceUrl" class="bg-white p-4 rounded-lg shadow-sm mb-4 flex justify-between items-center">
      <div>
        <div class="text-sm font-semibold text-gray-500 mb-1">Instance URL</div>
        <a 
          :href="instanceUrl" 
          target="_blank" 
          class="text-blue-600 hover:underline flex items-center"
        >
          {{ instanceUrl }}
          <i class="pi pi-external-link ml-1 text-xs"></i>
        </a>
      </div>
      <Tag :value="instanceStatus || 'Unknown'" :severity="getInstanceSeverity(instanceStatus)" />
    </div>
    
    <TabView class="instance-tabs">
      <!-- Storage Information Tab -->
      <TabPanel header="Storage Information">
        <div class="storage-info p-4">
          <h3 class="text-lg font-semibold mb-3">Storage Usage</h3>
          <div class="flex gap-4 mb-4">
            <div class="storage-summary bg-white p-4 rounded-lg shadow-sm flex-1">
              <div class="flex justify-between mb-2">
                <span class="text-gray-600">Total Storage</span>
                <span class="font-bold">{{ instanceData.resource_storage_gb || 500 }} GB</span>
              </div>
              <div class="flex justify-between mb-2">
                <span class="text-gray-600">Used Storage</span>
                <span class="font-bold text-blue-600">{{ Math.round(instanceData.resource_storage_gb * 0.65) || 325 }} GB</span>
              </div>
              <div class="flex justify-between">
                <span class="text-gray-600">Free Storage</span>
                <span class="font-bold text-green-600">{{ Math.round(instanceData.resource_storage_gb * 0.35) || 175 }} GB</span>
              </div>
            </div>
            <div class="storage-trend bg-white p-4 rounded-lg shadow-sm flex-1">
              <h4 class="text-md font-medium mb-2">Monthly Growth Rate</h4>
              <div class="text-2xl font-bold text-purple-600">+8.5%</div>
              <div class="text-sm text-gray-500">Compared to previous month</div>
            </div>
          </div>
          <div class="storage-chart bg-white p-4 rounded-lg shadow-sm" style="height: 300px">
            <Chart type="line" :data="storageChartData" :options="chartOptions" />
          </div>
        </div>
      </TabPanel>
      
      <!-- Cases Created Tab -->
      <TabPanel header="Cases Created">
        <div class="cases-created p-4">
          <div class="filters mb-4 flex gap-4 bg-white p-4 rounded-lg shadow-sm">
            <div class="flex-1">
              <h3 class="text-lg font-semibold mb-3">Date Range</h3>
              <div class="flex gap-4">
                <div class="flex-1">
                  <label class="block text-sm font-medium text-gray-600 mb-1">From</label>
                  <Calendar v-model="casesDateRange.from" showIcon dateFormat="mm/dd/yy" class="w-full" />
                </div>
                <div class="flex-1">
                  <label class="block text-sm font-medium text-gray-600 mb-1">To</label>
                  <Calendar v-model="casesDateRange.to" showIcon dateFormat="mm/dd/yy" class="w-full" />
                </div>
              </div>
            </div>
          </div>
          
          <DataTable 
            :value="mockCases" 
            :paginator="true" 
            :rows="5"
            :rowsPerPageOptions="[5, 10, 20]"
            responsiveLayout="scroll"
            class="cases-table"
          >
            <Column field="id" header="Case ID" :sortable="true"></Column>
            <Column field="status" header="Status" :sortable="true">
              <template #body="slotProps">
                <Tag :value="slotProps.data.status" :severity="getStatusSeverity(slotProps.data.status)" />
              </template>
            </Column>
            <Column field="created_by" header="Created by" :sortable="true"></Column>
            <Column field="created_at" header="Created date" :sortable="true"></Column>
          </DataTable>
        </div>
      </TabPanel>
      
      <!-- Users Tab -->
      <TabPanel header="Users">
        <div class="users-info p-4">
          <div class="flex gap-4 mb-4">
            <div class="user-summary bg-white p-4 rounded-lg shadow-sm flex-1">
              <div class="flex justify-between mb-2">
                <span class="text-gray-600">Total Users</span>
                <span class="font-bold">{{ mockUsers.length }}</span>
              </div>
              <div class="flex justify-between mb-2">
                <span class="text-gray-600">Active Users</span>
                <span class="font-bold text-green-600">{{ mockUsers.filter(u => u.status === 'active').length }}</span>
              </div>
              <div class="flex justify-between">
                <span class="text-gray-600">Admins</span>
                <span class="font-bold text-purple-600">{{ mockUsers.filter(u => u.role === 'admin').length }}</span>
              </div>
            </div>
            <div class="user-activity bg-white p-4 rounded-lg shadow-sm flex-1">
              <h4 class="text-md font-medium mb-2">Last Active User</h4>
              <div class="text-md font-bold">{{ mockUsers[0].name }}</div>
              <div class="text-sm text-gray-500">{{ mockUsers[0].last_activity }}</div>
            </div>
          </div>
          
          <DataTable 
            :value="mockUsers" 
            :paginator="true" 
            :rows="5"
            :rowsPerPageOptions="[5, 10, 20]"
            responsiveLayout="scroll"
            class="users-table"
          >
            <Column field="name" header="Name" :sortable="true"></Column>
            <Column field="email" header="Email" :sortable="true"></Column>
            <Column field="role" header="Role" :sortable="true">
              <template #body="slotProps">
                <Tag :value="slotProps.data.role" severity="info" />
              </template>
            </Column>
            <Column field="status" header="Status" :sortable="true">
              <template #body="slotProps">
                <Tag :value="slotProps.data.status" :severity="getUserStatusSeverity(slotProps.data.status)" />
              </template>
            </Column>
            <Column field="last_activity" header="Last Activity" :sortable="true"></Column>
          </DataTable>
        </div>
      </TabPanel>
      
      <!-- Instance Parameters Tab (Updated with Toggles) -->
      <TabPanel header="Instance Parameters">
        <div class="instance-params p-4">
          <div class="grid grid-cols-1 lg:grid-cols-2 gap-6">
            <!-- General Settings -->
            <div class="param-group bg-white p-4 rounded-lg shadow-sm">
              <h3 class="text-lg font-semibold mb-3 text-blue-700">General Settings</h3>
              <div class="params-list">
                <div class="param-item flex justify-between py-2 border-b border-gray-100">
                  <span class="text-gray-600">Storage Allocation</span>
                  <div class="flex items-center">
                    <InputNumber v-model="instanceData.resource_storage_gb" :min="1" :max="1000" 
                      showButtons buttonLayout="horizontal" size="small" 
                      class="w-24" :disabled="!isEditing" />
                    <span class="ml-2">GB</span>
                  </div>
                </div>
                <div class="param-item flex justify-between py-2 border-b border-gray-100">
                  <span class="text-gray-600">Third Party Browser</span>
                  <Dropdown v-model="instanceData.third_party_browser" 
                    :options="['weasis', 'horos', 'custom']" 
                    class="w-32" :disabled="!isEditing" />
                </div>
                <div class="param-item flex justify-between py-2 border-b border-gray-100">
                  <span class="text-gray-600">Timeline Type</span>
                  <Dropdown v-model="instanceData.timeline_type" 
                    :options="['list', 'calendar', 'both']" 
                    class="w-32" :disabled="!isEditing" />
                </div>
                <div class="param-item flex justify-between py-2">
                  <Button v-if="!isEditing" icon="pi pi-pencil" label="Edit Settings" 
                    @click="isEditing = true" severity="secondary" text />
                  <div v-else class="flex gap-2">
                    <Button icon="pi pi-check" label="Save" @click="saveInstanceSettings" 
                      severity="success" />
                    <Button icon="pi pi-times" label="Cancel" @click="cancelEdit" 
                      severity="secondary" text />
                  </div>
                </div>
              </div>
            </div>
            
            <!-- Feature Toggles -->
            <div class="param-group bg-white p-4 rounded-lg shadow-sm">
              <div class="flex justify-between">
                <h3 class="text-lg font-semibold mb-3 text-blue-700">Feature Toggles</h3>
                <div class="flex items-center">
                  <Button v-if="!isEditing" icon="pi pi-pencil" label="Edit Features" 
                    @click="isEditing = true" severity="secondary" text size="small" />
                </div>
              </div>
              
              <div class="grid grid-cols-1 gap-3 mt-4">
                <div v-for="(field, index) in featureToggles" :key="index" 
                    class="toggle-item flex justify-between items-center py-2 px-3 hover:bg-gray-50 rounded-md">
                  <span class="text-gray-700">{{ formatFeatureName(field.name) }}</span>
                  <InputSwitch v-model="instanceData[field.name]" :disabled="!isEditing" />
                </div>
              </div>
            </div>
            
            <!-- Document Features -->
            <div class="param-group bg-white p-4 rounded-lg shadow-sm">
              <h3 class="text-lg font-semibold mb-3 text-blue-700">Document Features</h3>
              <div class="grid grid-cols-1 gap-3 mt-4">
                <div v-for="(field, index) in documentFeatures" :key="index" 
                    class="toggle-item flex justify-between items-center py-2 px-3 hover:bg-gray-50 rounded-md">
                  <span class="text-gray-700">{{ formatFeatureName(field.name) }}</span>
                  <InputSwitch v-model="instanceData[field.name]" :disabled="!isEditing" />
                </div>
              </div>
            </div>
            
            <!-- Workflow Features -->
            <div class="param-group bg-white p-4 rounded-lg shadow-sm">
              <h3 class="text-lg font-semibold mb-3 text-blue-700">Workflow Features</h3>
              <div class="grid grid-cols-1 gap-3 mt-4">
                <div v-for="(field, index) in workflowFeatures" :key="index" 
                    class="toggle-item flex justify-between items-center py-2 px-3 hover:bg-gray-50 rounded-md">
                  <span class="text-gray-700">{{ formatFeatureName(field.name) }}</span>
                  <InputSwitch v-model="instanceData[field.name]" :disabled="!isEditing" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </TabPanel>
    </TabView>
  </div>
</template>

<script setup>
import { ref, computed, onMounted } from 'vue';
import TabView from 'primevue/tabview';
import TabPanel from 'primevue/tabpanel';
import Chart from 'primevue/chart';
import Calendar from 'primevue/calendar';
import MultiSelect from 'primevue/multiselect';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import Tag from 'primevue/tag';
import InputSwitch from 'primevue/inputswitch';
import InputNumber from 'primevue/inputnumber';
import Dropdown from 'primevue/dropdown';
import Button from 'primevue/button';
import { useToast } from 'primevue/usetoast';
import { faker } from '@faker-js/faker';

const props = defineProps({
  customerId: {
    type: [String, Number],
    required: true
  },
  customerName: {
    type: String,
    default: 'Customer'
  },
  instanceUrl: {
    type: String,
    default: ''
  },
  instanceStatus: {
    type: String,
    default: 'Unknown'
  }
});

const toast = useToast();
const isEditing = ref(false);

// Instance ID
const instanceId = ref(faker.string.alphanumeric(10));

// Instance Data based on Elixir schema
const instanceData = ref({
  resource_storage_gb: 500,
  third_party_browser: 'weasis',
  audit_reports_enabled: true,
  qr_codes_preferred: false,
  radiology_enabled: true,
  resources_enabled: true,
  emails_enabled: true,
  invoicing_enabled: true, 
  register_enabled: false,
  doq_exchange_enabled: true,
  contemporaneous_notes_enabled: true,
  time_tracking_enabled: true,
  worklist_enabled: true,
  remote_backups_enabled: false,
  doq_builder_enabled: true,
  doq_builder_letter_enabled: true,
  doq_builder_report_enabled: true,
  disable_doq_exchange_download: false,
  scope_register: false,
  user_documents_enabled: true,
  publications_enabled: false,
  timeline_type: 'list',
  reminders_enabled: true,
  timeline_enabled: true,
  dashboard_enabled: true
});

// Group feature toggles
const featureToggles = [
  { name: 'dashboard_enabled' },
  { name: 'audit_reports_enabled' },
  { name: 'emails_enabled' },
  { name: 'invoicing_enabled' },
  { name: 'publications_enabled' },
  { name: 'register_enabled' },
  { name: 'remote_backups_enabled' },
  { name: 'scope_register' }
];

const documentFeatures = [
  { name: 'doq_exchange_enabled' },
  { name: 'disable_doq_exchange_download' },
  { name: 'doq_builder_enabled' },
  { name: 'user_documents_enabled' }
];

const workflowFeatures = [
  { name: 'timeline_enabled' },
  { name: 'reminders_enabled' },
  { name: 'contemporaneous_notes_enabled' },
  { name: 'time_tracking_enabled' },
  { name: 'worklist_enabled' },
  { name: 'radiology_enabled' },
  { name: 'resources_enabled' },
  { name: 'qr_codes_preferred' }
];

// Format feature names for display
const formatFeatureName = (name) => {
  return name
    .replace(/_/g, ' ')
    .replace(/enabled/g, '')
    .replace(/doq/g, 'DoQ')
    .split(' ')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ')
    .trim();
};

// Function to save instance settings
const saveInstanceSettings = () => {
  // Here you would typically make an API call to save the settings
  toast.add({
    severity: 'success',
    summary: 'Settings Saved',
    detail: 'Instance settings have been updated successfully',
    life: 3000
  });
  isEditing.value = false;
};

// Function to cancel edits
const cancelEdit = () => {
  // Here you would typically reset the form
  toast.add({
    severity: 'info',
    summary: 'Edits Cancelled',
    detail: 'Changes have been discarded',
    life: 3000
  });
  isEditing.value = false;
};

// Generate more realistic random storage data
const generateStorageData = () => {
  // Create a slightly increasing trend
  const baseData = Array.from({ length: 7 }, (_, i) => 
    Math.round(100 + (i * 30) + (Math.random() * 30))
  );
  
  // Documents should somewhat correlate with storage
  const docData = baseData.map(val => 
    Math.round(val / 10 + Math.random() * 5)
  );
  
  return {
    storage: baseData,
    documents: docData
  };
};

const randomData = generateStorageData();

// Storage Information Tab Data
const storageChartData = ref({
  labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
  datasets: [
    {
      label: 'Used Storage (GB)',
      data: randomData.storage,
      fill: true,
      backgroundColor: 'rgba(54, 162, 235, 0.2)',
      borderColor: 'rgba(54, 162, 235, 1)',
      tension: 0.4
    },
    {
      label: 'Documents Count (thousands)',
      data: randomData.documents,
      fill: true,
      backgroundColor: 'rgba(255, 99, 132, 0.2)',
      borderColor: 'rgba(255, 99, 132, 1)',
      tension: 0.4
    }
  ]
});

const chartOptions = ref({
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      position: 'top'
    }
  }
});

// Cases Tab Data
const casesDateRange = ref({
  from: new Date(new Date().setMonth(new Date().getMonth() - 1)),
  to: new Date()
});

// Generate more realistic case data
const generateCases = (count = 6) => {
  const types = ['Document Upload', 'Contract Review', 'Legal Approval', 'Signature Request', 'Data Query'];
  const statuses = ['completed', 'active', 'pending'];
  
  return Array.from({ length: count }, (_, i) => {
    const type = types[Math.floor(Math.random() * types.length)];
    const status = statuses[Math.floor(Math.random() * statuses.length)];
    const date = faker.date.recent({ days: 60 });
    const firstName = faker.person.firstName();
    const lastName = faker.person.lastName();
    
    return {
      id: `CS-${date.getFullYear()}-${faker.string.numeric(3)}`,
      title: faker.lorem.sentence(3).replace('.', ''),
      type,
      created_at: date.toISOString().split('T')[0],
      created_by: `${firstName} ${lastName}`,
      status
    };
  }).sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
};

const mockCases = ref([
  {
    id: '16',
    status: 'pending',
    created_by: 'Christoph McAllen',
    created_at: '11/03/2025 08:30'
  },
  ...generateCases(7)
]);

// Generate realistic user data
const generateUsers = (count = 7) => {
  const roles = ['admin', 'user'];
  const statuses = ['active', 'inactive'];
  const activityItems = ['Just now', '5 minutes ago', '1 hour ago', '2 hours ago', 'Yesterday', '2 days ago', '1 week ago'];
  
  return Array.from({ length: count }, (_, i) => {
    const firstName = faker.person.firstName();
    const lastName = faker.person.lastName();
    
    return {
      name: `${firstName} ${lastName}`,
      email: faker.internet.email({ firstName, lastName }).toLowerCase(),
      role: roles[Math.floor(Math.random() * (i === 0 ? 1 : roles.length))], // Ensure at least one admin
      status: statuses[Math.floor(Math.random() * (i < count - 2 ? 1 : statuses.length))], // Ensure mostly active
      last_activity: activityItems[Math.min(i, activityItems.length - 1)]
    };
  });
};

const mockUsers = ref(generateUsers(7));

// Severity methods
const getCaseSeverity = (type) => {
  switch(type) {
    case 'Contract Review': return 'warning';
    case 'Legal Approval': return 'danger';
    case 'Signature Request': return 'info';
    case 'Data Query': return 'success';
    default: return 'info';
  }
};

const getStatusSeverity = (status) => {
  switch(status) {
    case 'active': return 'info';
    case 'completed': return 'success';
    case 'pending': return 'warning';
    default: return 'secondary';
  }
};

const getUserStatusSeverity = (status) => {
  switch(status) {
    case 'active': return 'success';
    case 'inactive': return 'danger';
    default: return 'info';
  }
};

const getInstanceSeverity = (status) => {
  switch(status) {
    case 'active': return 'success';
    case 'inactive': return 'danger';
    default: return 'secondary';
  }
};

// Lifecycle methods
onMounted(() => {
  // Here you would typically fetch the instance data
  console.log(`Fetching instance data for customer ID: ${props.customerId}`);
});
</script>

<style scoped>
/* Instance details styles */
.instance-details {
  margin: 0.5rem 1rem 1.5rem;
}

:deep(.p-tabview-nav) {
  border-bottom: 1px solid #e2e8f0;
}

:deep(.p-tabview-nav li .p-tabview-nav-link) {
  background-color: #f8fafc;
  color: #64748b;
  padding: 1rem 1.5rem;
  font-weight: 600;
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
  transition: background-color 0.2s, color 0.2s;
}

:deep(.p-tabview-nav li .p-tabview-nav-link:not(.p-disabled):focus) {
  box-shadow: none;
}

:deep(.p-tabview-nav li.p-highlight .p-tabview-nav-link) {
  background-color: #fff;
  color: #3b82f6;
  border-color: #e2e8f0;
  border-bottom-color: #fff;
}

:deep(.p-tabview-panels) {
  padding: 0;
  background-color: #fff;
  border-bottom-left-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
  border: 1px solid #e2e8f0;
  border-top: none;
}

/* Make calendar and multiselect inputs full width */
:deep(.p-calendar) {
  width: 100%;
}

:deep(.p-multiselect) {
  width: 100%;
}

/* Feature toggle styling */
.toggle-item {
  transition: all 0.2s ease-in-out;
}

.toggle-item:hover {
  background-color: #f8fafc;
}

:deep(.p-inputswitch.p-inputswitch-checked .p-inputswitch-slider) {
  background: #4ade80 !important;
}
</style> 