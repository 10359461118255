import axios from 'axios';

export default {
  getSignupLinks() {
    return axios.get('/api/v1/signup_links');
  },

  getSignupLink(id) {
    return axios.get(`/api/v1/signup_links/${id}`);
  },

  createSignupLink(signupLink) {
    return axios.post('/api/v1/signup_links', { signup_link: signupLink });
  },

  deleteSignupLink(id) {
    return axios.delete(`/api/v1/signup_links/${id}`);
  },

  resendSignupLink(id) {
    return axios.post(`/api/v1/signup_links/${id}/resend`);
  },

  // Public endpoints for completing signup
  getSignupForm(token) {
    return axios.get(`/signup/${token}.json`);
  },

  completeSignup(token, customerData) {
    return axios.post(`/signup/${token}/complete`, { customer: customerData });
  }
};