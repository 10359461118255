<template>
  <div class="p-6">
    <div class="bg-white rounded-lg shadow-md p-6 mb-6">
      <h1 class="text-2xl font-bold mb-4">Dashboard</h1>
      
      <div class="mb-6">
        <h2 class="text-xl font-semibold mb-3">Welcome, {{ user?.first_name }}!</h2>
        <p class="text-gray-700">
          This is your personal dashboard where you can manage your account and access all your important information.
        </p>
      </div>
      
      <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        <!-- Quick Stats -->
        <div class="bg-blue-50 p-4 rounded-lg border border-blue-200">
          <h3 class="text-lg font-semibold text-blue-700 mb-2">Account Status</h3>
          <p class="text-sm">Your account is active</p>
          <div class="mt-2 flex items-center">
            <span class="inline-block w-3 h-3 bg-green-500 rounded-full mr-2"></span>
            <span class="text-sm text-green-700">Active</span>
          </div>
        </div>
        
        <!-- 2FA Status -->
        <div class="bg-purple-50 p-4 rounded-lg border border-purple-200">
          <h3 class="text-lg font-semibold text-purple-700 mb-2">Two-Factor Auth</h3>
          <div v-if="user?.otp_enabled" class="flex items-center text-green-700">
            <span class="text-sm">Enabled ({{ get2FAMethodLabel }})</span>
          </div>
          <div v-else class="flex items-center text-yellow-600">
            <span class="text-sm">Not enabled</span>
          </div>
          <div class="mt-2">
            <router-link to="/profile" class="text-sm text-blue-600 hover:text-blue-800">
              Manage settings
            </router-link>
          </div>
        </div>
        
        <!-- Recent Activity Placeholder -->
        <div class="bg-amber-50 p-4 rounded-lg border border-amber-200">
          <h3 class="text-lg font-semibold text-amber-700 mb-2">Recent Activity</h3>
          <p class="text-sm text-gray-600">Last login: {{ formatDate(new Date()) }}</p>
        </div>
      </div>
    </div>
    
    <!-- Actions Cards -->
    <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
      <div 
        v-for="(action, index) in quickActions" 
        :key="index"
        class="bg-white p-4 rounded-lg shadow-md hover:shadow-lg transition-shadow duration-300 cursor-pointer"
        @click="navigateTo(action.link)"
      >
        <h3 class="font-semibold mb-2">{{ action.title }}</h3>
        <p class="text-sm text-gray-600">{{ action.description }}</p>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed } from 'vue'
import { useRouter } from 'vue-router'
import { useAuthStore } from '../store/auth'

const router = useRouter()
const authStore = useAuthStore()
const user = computed(() => authStore.currentUser)

const get2FAMethodLabel = computed(() => {
  if (!user.value) return ''
  
  return user.value.two_factor_method === 'sms' ? 'SMS' : 'Authenticator App'
})

const quickActions = [
  {
    title: 'Profile Settings',
    description: 'Update your personal information and preferences',
    link: '/profile'
  },
  {
    title: 'Security',
    description: 'Manage your password and two-factor authentication',
    link: '/change-password'
  },
  {
    title: 'User Management',
    description: 'View and manage users',
    link: '/users'
  },
  {
    title: 'Help & Support',
    description: 'Get assistance and view documentation',
    link: '#'
  }
]

const formatDate = (date) => {
  return new Intl.DateTimeFormat('en-US', {
    weekday: 'short',
    month: 'short',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric'
  }).format(date)
}

const navigateTo = (path) => {
  router.push(path)
}
</script>