<template>
  <div class="bg-white p-6 rounded-lg shadow-md">
    <h2 class="text-2xl font-bold mb-6">Change Your Password</h2>
    
    <form @submit.prevent="changePassword" class="mt-6">
      <div class="mb-4">
        <label for="current_password" class="block text-gray-700 text-sm font-bold mb-2">Current Password</label>
        <input 
          type="password" 
          id="current_password" 
          v-model="form.currentPassword" 
          required
          class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
        />
      </div>
      
      <div class="mb-4">
        <label for="new_password" class="block text-gray-700 text-sm font-bold mb-2">New Password</label>
        <input 
          type="password" 
          id="new_password" 
          v-model="form.newPassword" 
          required
          class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
        />
        <p class="text-gray-600 text-sm mt-1">
          Password must be at least 8 characters long
        </p>
      </div>
      
      <div class="mb-6">
        <label for="password_confirmation" class="block text-gray-700 text-sm font-bold mb-2">Confirm New Password</label>
        <input 
          type="password" 
          id="password_confirmation" 
          v-model="form.passwordConfirmation" 
          required
          class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
        />
      </div>
      
      <div class="flex items-center justify-between">
        <button 
          type="submit" 
          class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
          :disabled="authStore.loading || !isValid"
        >
          {{ authStore.loading ? 'Updating...' : 'Update Password' }}
        </button>
        <router-link 
          to="/profile" 
          class="inline-block align-baseline font-bold text-sm text-red-500 hover:text-red-800"
        >
          Cancel
        </router-link>
      </div>
    </form>
    
    <div v-if="successMessage" class="mt-4 p-3 bg-green-100 text-green-700 rounded">
      {{ successMessage }}
    </div>
  </div>
</template>

<script setup>
import { ref, computed } from 'vue'
import { useRouter } from 'vue-router'
import { useAuthStore } from '../../store/auth'
import axios from 'axios'

const router = useRouter()
const authStore = useAuthStore()
const successMessage = ref('')

const form = ref({
  currentPassword: '',
  newPassword: '',
  passwordConfirmation: ''
})

const isValid = computed(() => {
  return form.value.newPassword.length >= 8 && 
         form.value.newPassword === form.value.passwordConfirmation
})

const changePassword = async () => {
  if (!isValid.value) return
  
  try {
    authStore.loading = true
    authStore.error = null
    
    await axios.patch('/api/v1/users/update_password', {
      user: {
        current_password: form.value.currentPassword,
        password: form.value.newPassword,
        password_confirmation: form.value.passwordConfirmation
      }
    })
    
    successMessage.value = 'Password updated successfully!'
    form.value = {
      currentPassword: '',
      newPassword: '',
      passwordConfirmation: ''
    }
    
    // Redirect after 2 seconds
    setTimeout(() => {
      router.push('/profile')
    }, 2000)
  } catch (error) {
    authStore.error = error.response?.data?.message || 'Failed to update password'
  } finally {
    authStore.loading = false
  }
}
</script>