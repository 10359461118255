<template>
  <div class="bg-white p-6 rounded-lg shadow-md max-w-md mx-auto">
    <h2 class="text-2xl font-light mb-4 text-center">{{ isEditMode ? 'Edit user' : 'Add new ground control user' }}</h2>
    
    <div v-if="!isEditMode" class="bg-blue-50 border-l-4 border-blue-500 p-4 mb-6 rounded">
      <div class="flex">
        <div class="flex-shrink-0">
          <i class="pi pi-info-circle text-blue-500 text-xl mr-2"></i>
        </div>
        <div>
          <h3 class="font-medium text-blue-800">Access Information</h3>
          <p class="text-sm text-blue-700 mt-1">
            The user will receive an email with login instructions. They can access the system at:
            <a href="https://gc.alldoq.com" target="_blank" class="font-bold text-blue-800 hover:underline">https://gc.alldoq.com</a>
          </p>
          <p class="text-sm text-blue-700 mt-1">
            A temporary password will be automatically generated and sent to them.
          </p>
        </div>
      </div>
    </div>
    
    <div v-if="successMessage" class="mb-4 p-3 bg-green-100 text-green-800 rounded">
      {{ successMessage }}
    </div>
    
    <form @submit.prevent="register">
      <div class="mb-4">
        <label for="email" class="block text-gray-700 text-sm font-bold mb-2">Email</label>
        <input 
          type="email" 
          id="email" 
          v-model="form.email" 
          required
          class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
        />
      </div>
      
      <div class="mb-4">
        <label for="first_name" class="block text-gray-700 text-sm font-bold mb-2">First Name</label>
        <input 
          type="text" 
          id="first_name" 
          v-model="form.first_name" 
          required
          class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
        />
      </div>
      
      <div class="mb-4">
        <label for="last_name" class="block text-gray-700 text-sm font-bold mb-2">Last Name</label>
        <input 
          type="text" 
          id="last_name" 
          v-model="form.last_name" 
          required
          class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
        />
      </div>
      
      <div class="mb-4">
        <label for="phone_number" class="block text-gray-700 text-sm font-bold mb-2">Phone Number</label>
        <input 
          type="tel" 
          id="phone_number" 
          v-model="form.phone_number" 
          required
          class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
        />
        <p class="text-sm text-gray-600 mt-1">Required for two-factor authentication</p>
      </div>
      
      
      <div class="flex items-center justify-between">
        <button 
          type="submit" 
          class="bg-blue-900 hover:bg-blue-800 text-white font-light py-2 px-4 rounded focus:outline-none focus:shadow-outline"
          :disabled="authStore.loading"
        >
          {{ authStore.loading ? (isEditMode ? 'Updating user...' : 'Creating user...') : (isEditMode ? 'Update user' : 'Create user') }}
        </button>
        <router-link to="/users" class="inline-block align-baseline font-bold text-sm text-blue-500 hover:text-blue-800">
          Cancel
        </router-link>
      </div>
    </form>
  </div>
</template>

<script setup>
import { reactive, ref, onMounted, computed } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import { useAuthStore } from '../../store/auth'
import { useToast } from 'vue-toastification'
import { userService } from '../../utils/userService'
import 'primeicons/primeicons.css'

const router = useRouter()
const route = useRoute()
const authStore = useAuthStore()
const toast = useToast()
const successMessage = ref('')
const userId = ref(null)

// Check if in edit mode
const isEditMode = computed(() => !!route.query.edit)

const form = reactive({
  email: '',
  first_name: '',
  last_name: '',
  phone_number: ''
})

const register = async () => {
  try {
    if (isEditMode.value) {
      // Update existing user
      const result = await userService.updateUser(userId.value, form)
      
      if (result.status === 'success') {
        successMessage.value = 'User updated successfully'
        toast.success('User updated successfully')
        setTimeout(() => {
          router.push('/users')
        }, 1000)
      } else {
        toast.error(result.errors?.join(', ') || 'Failed to update user')
      }
    } else {
      // Create new user
      const result = await userService.createUser(form)
      
      if (result.status === 'success') {
        successMessage.value = result.message || 'User created successfully. Login details have been emailed.'
        toast.success('User created successfully')
        setTimeout(() => {
          router.push('/users')
        }, 1000)
      }
    }
  } catch (error) {
    console.error('Error:', error)
    const errorMessage = error.response?.data?.errors?.join(', ') || 'An error occurred'
    toast.error(errorMessage)
  }
}

// Check if user is authenticated, only authenticated users can create new users
onMounted(() => {
  if (!authStore.isAuthenticated) {
    router.push('/login')
    return
  }
  
  // If editing, load user data
  if (isEditMode.value) {
    const editingUser = JSON.parse(localStorage.getItem('editingUser') || '{}')
    if (editingUser.id) {
      userId.value = editingUser.id
      form.email = editingUser.email || ''
      form.first_name = editingUser.first_name || ''
      form.last_name = editingUser.last_name || ''
      form.phone_number = editingUser.phone_number || ''
    } else {
      toast.error('User data not found')
      router.push('/users')
    }
  }
})
</script>