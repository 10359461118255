<template>
  <div class="bg-white p-6 rounded-lg shadow-md max-w-md mx-auto">
    <div class="mb-6">
      <div class="flex flex-col items-center">
        <div class="relative inline-block">
          <h1 class="text-3xl font-bold" style="color: #0605f6;">ALLDOQ</h1>
          <span class="absolute right-0 text-sm text-gray-500" style="top: 30px;">ground control</span>
        </div>
      </div>
    </div>
    
    <form @submit.prevent="login">
      <div class="mb-4">
        <label for="email" class="block text-gray-700 text-sm font-bold mb-2">Email</label>
        <input 
          type="email" 
          id="email" 
          v-model="email" 
          required
          class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
        />
      </div>
      
      <div class="mb-6">
        <label for="password" class="block text-gray-700 text-sm font-bold mb-2">Password</label>
        <input 
          type="password" 
          id="password" 
          v-model="password" 
          required
          class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
        />
      </div>
      
      <div class="flex items-center justify-center">
        <button 
          type="submit" 
          class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
          :disabled="authStore.loading"
        >
          {{ authStore.loading ? 'Logging in...' : 'Login' }}
        </button>
      </div>
    </form>
  </div>
</template>

<script setup>
import { ref } from 'vue'
import { useRouter } from 'vue-router'
import { useAuthStore } from '../../store/auth'

const email = ref('')
const password = ref('')
const router = useRouter()
const authStore = useAuthStore()

const login = async () => {
  try {
    const result = await authStore.login(email.value, password.value)
    
    if (result.status === 'pending_2fa') {
      router.push('/verify-2fa')
    } else if (result.status === 'success') {
      router.push('/dashboard')
    }
  } catch (error) {
    // Error is handled in the store
  }
}
</script>