<template>
  <!-- Special layout for CompleteSignup page - this check needs to come first -->
  <div v-if="isCompleteSignupPage" class="min-h-screen bg-gray-100">
    <router-view />
  </div>
  
  <!-- Authenticated layout with header and sidebar -->
  <div v-else-if="authStore.isAuthenticated" class="flex h-screen bg-gray-100">
    <SidebarNav />
    <div class="flex-1 flex flex-col overflow-hidden">
      <AppHeader 
        :is-authenticated="authStore.isAuthenticated" 
        :user-name="userName" 
        :user-initials="userInitials"
        @logout="logout"
      />
      <MainLayout>
        <template #alerts>
          <!-- Show alert messages -->
          <div v-if="authStore.error" class="mb-4 p-3 bg-red-100 text-red-700 rounded">
            {{ Array.isArray(authStore.error) ? authStore.error.join(', ') : authStore.error }}
          </div>
        </template>
        <router-view />
      </MainLayout>
    </div>
  </div>
  
  <!-- Unauthenticated layout - content only -->
  <div v-else class="h-screen bg-gray-100 flex items-center justify-center p-4">
    <div class="w-full max-w-md">
      <div v-if="authStore.error" class="mb-4 p-3 bg-red-100 text-red-700 rounded">
        {{ Array.isArray(authStore.error) ? authStore.error.join(', ') : authStore.error }}
      </div>
      <router-view />
    </div>
  </div>
</template>

<script setup>
import { useAuthStore } from './store/auth'
import { useRouter } from 'vue-router'
import { computed, ref } from 'vue'

// Import components
import SidebarNav from './components/SidebarNav.vue'
import AppHeader from './components/AppHeader.vue'
import MainLayout from './components/MainLayout.vue'

const authStore = useAuthStore()
const router = useRouter()

// Add a computed property to check if this is the CompleteSignup page
const isCompleteSignupPage = computed(() => {
  const currentRoute = router.currentRoute.value;
  // Check both the route name and if the path starts with /signup/
  return currentRoute.name === 'CompleteSignup' || currentRoute.path.startsWith('/signup/')
})

const userName = computed(() => {
  return authStore.user?.name || 'User'
})

const userInitials = computed(() => {
  if (!authStore.user?.name) return 'U'
  return authStore.user.name
    .split(' ')
    .map(n => n[0])
    .join('')
    .toUpperCase()
    .substring(0, 2)
})

const logout = async () => {
  await authStore.logout()
  router.push('/login')
}
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap');
@import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.0.0-beta3/css/all.min.css');

body {
  font-family: 'Inter', sans-serif;
}
</style>
