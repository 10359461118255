<template>
  <Button 
    :label="buttonLabel || 'New Signup Link'" 
    :icon="buttonIcon || 'pi pi-plus'" 
    :class="buttonClass || 'bg-blue-900 hover:bg-blue-800 text-white border-blue-900 py-3 px-6'"
    @click="openDialog" 
  />
  
  <Dialog 
    :visible="dialogVisible" 
    @update:visible="dialogVisible = $event"
    :style="{ width: '450px' }" 
    header="Create New Signup Link" 
    :modal="true"
    :closable="true"
    :dismissableMask="false"
    class="signup-link-dialog"
  >
    <div class="p-fluid p-4">
      <div class="field mb-5">
        <label for="email" class="block mb-4 font-medium text-gray-700">Email</label>
        <InputText 
          id="email" 
          v-model="emailValue" 
          :class="{'p-invalid': v$.email.$invalid && v$.email.$dirty}"
          @blur="v$.email.$touch()"
          placeholder="Enter email address"
          class="p-3 border border-gray-300 w-full"
        />
        <small v-if="v$.email.$error" class="p-error mt-2 block text-red-500">{{ v$.email.$errors[0].$message }}</small>
      </div>
    </div>
    <template #footer>
      <div class="flex justify-end gap-3 p-3">
        <Button 
          label="Cancel" 
          icon="pi pi-times" 
          class="p-button-text py-3 px-6" 
          @click="closeDialog" 
        />
        <Button 
          label="Create" 
          icon="pi pi-check" 
          class="bg-blue-900 hover:bg-blue-800 text-white border-blue-900 py-3 px-6" 
          @click="handleSubmit" 
          :loading="loading"
          :disabled="loading || v$.$invalid"
        />
      </div>
    </template>
  </Dialog>
</template>

<script setup>
import { ref, reactive, computed } from 'vue'
import { useVuelidate } from '@vuelidate/core'
import { required, email } from '@vuelidate/validators'
import { useToast } from 'vue-toastification'
import signupLinkService from '../utils/signupLinkService'

// PrimeVue components
import Button from 'primevue/button'
import InputText from 'primevue/inputtext'
import Dialog from 'primevue/dialog'

const props = defineProps({
  buttonLabel: String,
  buttonIcon: String,
  buttonClass: String,
  onSuccess: {
    type: Function,
    default: null
  }
})

const emit = defineEmits(['success'])

// Toast for notifications
const toast = useToast()

// Dialog state
const dialogVisible = ref(false)
const loading = ref(false)

// Form data
const emailForm = reactive({
  email: ''
})

// Validation rules
const rules = {
  email: { required, email }
}

const v$ = useVuelidate(rules, emailForm)

// Computed property for two-way binding
const emailValue = computed({
  get: () => emailForm.email,
  set: (value) => {
    emailForm.email = value
  }
})

// Event handlers
const openDialog = () => {
  resetForm()
  dialogVisible.value = true
}

const closeDialog = () => {
  dialogVisible.value = false
  resetForm()
}

// Handle form submission
const handleSubmit = async () => {
  const isValid = await v$.value.$validate()
  if (!isValid) return
  
  loading.value = true
  
  try {
    const response = await signupLinkService.createSignupLink({ email: emailForm.email })
    const data = response.data
    
    if (data.status === 'success') {
      toast.success('Signup link created and email sent successfully')
      closeDialog()
      
      // Notify parent component
      if (props.onSuccess) {
        props.onSuccess()
      }
      emit('success')
    } else {
      toast.error(data.message || 'Failed to create signup link')
    }
  } catch (error) {
    console.error('Error creating signup link:', error)
    if (error.response && error.response.data && error.response.data.errors) {
      toast.error(error.response.data.errors.join(', '))
    } else {
      toast.error('An error occurred while creating the signup link')
    }
  } finally {
    loading.value = false
  }
}

// Reset form
const resetForm = () => {
  emailForm.email = ''
  v$.value.$reset()
}

// Public methods exposed to parent
defineExpose({
  openDialog,
  closeDialog,
  resetForm
})
</script>

<style scoped>
/* Dialog styling */
:deep(.signup-link-dialog .p-dialog-header) {
  padding: 1.5rem;
  border-bottom: 1px solid #e5e7eb;
}

:deep(.signup-link-dialog .p-dialog-content) {
  padding: 0;
}

:deep(.signup-link-dialog .p-dialog-footer) {
  padding: 0;
  border-top: 1px solid #e5e7eb;
}

.p-button {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.p-inputtext {
  border: 1px solid #ced4da !important;
  border-radius: 4px !important;
  transition: border-color 0.2s, box-shadow 0.2s !important;
  width: 100% !important;
  display: block !important;
}

.p-inputtext:hover {
  border-color: #2563eb !important;
}

.p-inputtext:focus {
  border-color: #2563eb !important;
  box-shadow: 0 0 0 0.2rem rgba(37, 99, 235, 0.25) !important;
  outline: 0 none !important;
}

.p-invalid {
  border-color: #ef4444 !important;
}

.p-error {
  color: #ef4444 !important;
  margin-top: 0.25rem !important;
  font-size: 0.875rem !important;
  display: block !important;
  font-style: italic !important;
}
</style>