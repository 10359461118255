<template>
  <MainLayout>
    <template #header>
      <div class="flex justify-between items-center">
        <div>
          <h1 class="text-2xl font-bold mb-1">
            {{ customer?.business_name || 'Customer Profile' }}
          </h1>
          <div v-if="customer" class="text-gray-600">
            {{ customer.first_name }} {{ customer.last_name }} - {{ customer.job_title }}
          </div>
        </div>
        <div class="flex gap-2">
          <Button
            icon="pi pi-pencil"
            label="Edit Customer"
            class="p-button-outlined"
            style="background-color: #ffffcc; border: 2px solid #ff0000; z-index: 100;"
            @click="openEditModal"
          />
          <Button
            icon="pi pi-arrow-left"
            label="Back to Customers"
            class="p-button-outlined"
            @click="router.push({ name: 'Customers' })"
          />
        </div>
      </div>
    </template>

    <div v-if="loading" class="flex justify-center items-center h-64">
      <ProgressSpinner />
    </div>

    <div v-else-if="!customer" class="text-center p-8">
      <div class="text-xl text-red-500 mb-4">Customer not found</div>
      <Button
        label="Return to Customers"
        @click="router.push({ name: 'Customers' })"
      />
    </div>

    <div v-else>
      <TabView v-model:activeIndex="activeTab">
        <TabPanel header="Customer Info">
          <Card class="mb-4">
            <template #title>Contact Information</template>
            <template #content>
              <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
                <div>
                  <div class="mb-2 text-sm font-semibold text-gray-500">Business</div>
                  <div class="mb-4">{{ customer.business_name || 'N/A' }}</div>
                  
                  <div class="mb-2 text-sm font-semibold text-gray-500">Name</div>
                  <div class="mb-4">{{ customer.title }} {{ customer.first_name }} {{ customer.last_name }}</div>
                </div>
                
                <div>
                  <div class="mb-2 text-sm font-semibold text-gray-500">Job Title</div>
                  <div class="mb-4">{{ customer.job_title || 'N/A' }}</div>
                  
                  <div class="mb-2 text-sm font-semibold text-gray-500">Email</div>
                  <div class="mb-4">
                    <a :href="`mailto:${customer.email}`" class="text-blue-600 hover:underline">
                      {{ customer.email }}
                    </a>
                  </div>
                  
                  <div class="mb-2 text-sm font-semibold text-gray-500">Phone</div>
                  <div class="mb-4">
                    <a v-if="customer.phone_number" :href="`tel:${customer.phone_number}`" class="text-blue-600 hover:underline">
                      {{ customer.phone_number }}
                    </a>
                    <span v-else>N/A</span>
                  </div>
                </div>
              </div>
            </template>
          </Card>
        </TabPanel>

        <TabPanel header="ALLDOQ Instance">
          <div v-if="customer.alldoq_instance">
            <AllDoqInstanceDetails 
              :customerId="customer.id" 
              :customerName="`${customer.first_name} ${customer.last_name}`" 
              :instanceUrl="`https://${customer.alldoq_instance.subdomain}.alldoq.com`"
              :instanceStatus="customer.alldoq_instance.status"
            />
          </div>
          
          <div v-else class="p-4 text-center border rounded-lg bg-gray-50">
            <div class="text-lg mb-2">No AllDoq Instance</div>
            <div class="text-gray-600 mb-4">This customer doesn't have an AllDoq instance yet.</div>
          </div>
        </TabPanel>

        <TabPanel header="Installation">
          <Card class="mb-4" v-if="customer.alldoq_installation">
            <template #title>Installation Details</template>
            <template #content>
              <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
                <!-- Installation details would go here -->
                <div>
                  <div class="mb-2 text-sm font-semibold text-gray-500">Status</div>
                  <div class="mb-4">
                    <Tag :severity="getInstallationSeverity(customer.alldoq_installation.status)">
                      {{ customer.alldoq_installation.status }}
                    </Tag>
                  </div>
                </div>
              </div>
            </template>
          </Card>
          
          <div v-else class="p-4 text-center border rounded-lg bg-gray-50">
            <div class="text-lg mb-2">No Installation Information</div>
            <div class="text-gray-600 mb-4">No installation details available for this customer.</div>
          </div>
        </TabPanel>
      </TabView>
    </div>
    
    <!-- Edit Customer Modal -->
    <Dialog 
      v-model:visible="editModalVisible" 
      header="Edit customer details" 
      :style="{ width: '500px' }" 
      :modal="true"
      :closable="true"
    >
      <div class="p-fluid">
        <div class="field mb-4">
          <label for="title" class="block mb-2">Title</label>
          <Dropdown
            id="title"
            v-model="editForm.title"
            :options="titleOptions"
            optionLabel="label"
            optionValue="value"
            placeholder="Select Title"
            class="w-full"
            :class="{ 'p-invalid': formErrors.title }"
          />
          <small v-if="formErrors.title" class="p-error">{{ formErrors.title }}</small>
        </div>
        
        <div class="field mb-4">
          <label for="first_name" class="block mb-2">First Name</label>
          <InputText 
            id="first_name" 
            v-model="editForm.first_name" 
            class="w-full"
            :class="{ 'p-invalid': formErrors.first_name }"
          />
          <small v-if="formErrors.first_name" class="p-error">{{ formErrors.first_name }}</small>
        </div>
        
        <div class="field mb-4">
          <label for="last_name" class="block mb-2">Last Name</label>
          <InputText 
            id="last_name" 
            v-model="editForm.last_name" 
            class="w-full"
            :class="{ 'p-invalid': formErrors.last_name }"
          />
          <small v-if="formErrors.last_name" class="p-error">{{ formErrors.last_name }}</small>
        </div>
        
        <div class="field mb-4">
          <label for="phone_number" class="block mb-2">Phone Number</label>
          <InputText 
            id="phone_number" 
            v-model="editForm.phone_number" 
            class="w-full"
            :class="{ 'p-invalid': formErrors.phone_number }"
          />
          <small v-if="formErrors.phone_number" class="p-error">{{ formErrors.phone_number }}</small>
        </div>
        
        <div class="field mb-4">
          <label for="job_title" class="block mb-2">Job Title</label>
          <InputText 
            id="job_title" 
            v-model="editForm.job_title" 
            class="w-full"
            :class="{ 'p-invalid': formErrors.job_title }"
          />
          <small v-if="formErrors.job_title" class="p-error">{{ formErrors.job_title }}</small>
        </div>
        
        <div class="field mb-4">
          <label for="business_name" class="block mb-2">Business Name</label>
          <InputText 
            id="business_name" 
            v-model="editForm.business_name" 
            class="w-full"
            :class="{ 'p-invalid': formErrors.business_name }"
          />
          <small v-if="formErrors.business_name" class="p-error">{{ formErrors.business_name }}</small>
        </div>
      </div>
      
      <template #footer>
        <Button 
          label="Cancel" 
          icon="pi pi-times" 
          class="p-button-text" 
          @click="cancelEdit"
        />
        <Button 
          label="Save" 
          icon="pi pi-check" 
          :loading="saving" 
          @click="saveCustomer"
        />
      </template>
    </Dialog>
  </MainLayout>
</template>

<script setup>
import { ref, onMounted, watch, reactive } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useToast } from 'primevue/usetoast';
import { useAuthStore } from '../store/auth';
import customerService from '../utils/customerService';
import MainLayout from '../components/MainLayout.vue';
import TabView from 'primevue/tabview';
import TabPanel from 'primevue/tabpanel';
import Button from 'primevue/button';
import ProgressSpinner from 'primevue/progressspinner';
import Card from 'primevue/card';
import Tag from 'primevue/tag';
import Dialog from 'primevue/dialog';
import InputText from 'primevue/inputtext';
import Dropdown from 'primevue/dropdown';
import AllDoqInstanceDetails from '../components/AllDoqInstanceDetails.vue';

const route = useRoute();
const router = useRouter();
const toast = useToast();
const authStore = useAuthStore();

// Data
const customer = ref(null);
const loading = ref(true);
const activeTab = ref(0);
const editModalVisible = ref(false);
const saving = ref(false);
const editForm = reactive({
  title: '',
  first_name: '',
  last_name: '',
  phone_number: '',
  job_title: '',
  business_name: ''
});
const formErrors = reactive({});
const titleOptions = [
  { label: 'Mr', value: 'Mr' },
  { label: 'Mrs', value: 'Mrs' },
  { label: 'Miss', value: 'Miss' },
  { label: 'Ms', value: 'Ms' },
  { label: 'Dr', value: 'Dr' },
  { label: 'Prof', value: 'Prof' }
];

// Methods
const loadCustomer = async () => {
  loading.value = true;
  try {
    const response = await customerService.getCustomer(route.params.id);
    customer.value = response.data;
    console.log('Customer data loaded:', customer.value);
  } catch (error) {
    console.error('Error loading customer:', error);
    toast.add({
      severity: 'error',
      summary: 'Error',
      detail: 'Failed to load customer details',
      life: 3000
    });
  } finally {
    loading.value = false;
  }
};

const openEditModal = () => {
  console.log('Opening edit modal, customer data:', customer.value);
  // Populate form with current customer data
  if (customer.value) {
    editForm.title = customer.value.title;
    editForm.first_name = customer.value.first_name;
    editForm.last_name = customer.value.last_name;
    editForm.phone_number = customer.value.phone_number;
    editForm.job_title = customer.value.job_title;
    editForm.business_name = customer.value.business_name;
  } else {
    console.error('Cannot open edit modal: customer data is not available');
  }
  
  // Clear any previous errors
  Object.keys(formErrors).forEach(key => delete formErrors[key]);
  
  // Show modal
  editModalVisible.value = true;
  console.log('Modal visibility set to:', editModalVisible.value);
};

const cancelEdit = () => {
  editModalVisible.value = false;
};

const validateForm = () => {
  let isValid = true;
  Object.keys(formErrors).forEach(key => delete formErrors[key]);
  
  if (!editForm.title) {
    formErrors.title = 'Title is required';
    isValid = false;
  }
  
  if (!editForm.first_name) {
    formErrors.first_name = 'First name is required';
    isValid = false;
  }
  
  if (!editForm.last_name) {
    formErrors.last_name = 'Last name is required';
    isValid = false;
  }
  
  if (!editForm.phone_number) {
    formErrors.phone_number = 'Phone number is required';
    isValid = false;
  } else if (!/^\+?[\d\s\-\(\)]+$/.test(editForm.phone_number)) {
    formErrors.phone_number = 'Please enter a valid phone number';
    isValid = false;
  }
  
  if (!editForm.job_title) {
    formErrors.job_title = 'Job title is required';
    isValid = false;
  }
  
  if (!editForm.business_name) {
    formErrors.business_name = 'Business name is required';
    isValid = false;
  }
  
  return isValid;
};

const saveCustomer = async () => {
  if (!validateForm()) {
    return;
  }
  
  saving.value = true;
  try {
    const response = await customerService.updateCustomer(customer.value.id, {
      title: editForm.title,
      first_name: editForm.first_name,
      last_name: editForm.last_name,
      phone_number: editForm.phone_number,
      job_title: editForm.job_title,
      business_name: editForm.business_name
    });
    
    // Update the local customer data
    customer.value = response.data;
    
    // Close the modal
    editModalVisible.value = false;
    
    // Show success message
    toast.add({
      severity: 'success',
      summary: 'Success',
      detail: 'Customer details updated successfully',
      life: 3000
    });
  } catch (error) {
    console.error('Error updating customer:', error);
    
    // Check for validation errors from backend
    if (error.response && error.response.data && error.response.data.errors) {
      error.response.data.errors.forEach(err => {
        const field = err.toLowerCase().split(' ')[0];
        formErrors[field] = err;
      });
    } else {
      toast.add({
        severity: 'error',
        summary: 'Error',
        detail: 'Failed to update customer details',
        life: 3000
      });
    }
  } finally {
    saving.value = false;
  }
};

const formatDate = (dateString) => {
  if (!dateString) return 'N/A';
  const date = new Date(dateString);
  return new Intl.DateTimeFormat('en-US', {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit'
  }).format(date);
};

const getInstanceSeverity = (status) => {
  if (!status) return 'danger';
  
  switch (status.toLowerCase()) {
    case 'active':
      return 'success';
    case 'pending':
      return 'warning';
    case 'inactive':
      return 'danger';
    default:
      return 'info';
  }
};

const getInstallationSeverity = (status) => {
  if (!status) return 'danger';
  
  switch (status.toLowerCase()) {
    case 'completed':
      return 'success';
    case 'in_progress':
      return 'warning';
    case 'failed':
      return 'danger';
    default:
      return 'info';
  }
};

// Lifecycle
onMounted(async () => {
  console.log('Component mounted, auth state:', authStore.isAuthenticated);
  console.log('Route params:', route.params);
  
  if (authStore.isAuthenticated) {
    await loadCustomer();
    
    // Set active tab based on query parameter
    if (route.query.tab === 'alldoq') {
      activeTab.value = 1; // Index of the AllDoq Instance tab
    }
  } else {
    console.log('Not authenticated, redirecting to login');
    router.push({ name: 'Login' });
  }
});

// Watch for auth state changes
watch(
  () => authStore.isAuthenticated,
  (isAuthenticated) => {
    if (isAuthenticated) {
      loadCustomer();
    }
  }
);

// Watch for route params changes
watch(
  () => route.params.id,
  (newId) => {
    if (newId && authStore.isAuthenticated) {
      loadCustomer();
    }
  }
);

// Watch for tab changes in the URL
watch(
  () => route.query.tab,
  (newTab) => {
    if (newTab === 'alldoq') {
      activeTab.value = 1; // Index of the AllDoq Instance tab
    }
  }
);
</script>