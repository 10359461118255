import axios from 'axios';

export default {
  getCustomers() {
    console.log('Fetching all customers');
    return axios.get('/api/v1/customers');
  },

  getCustomer(id) {
    console.log('Fetching customer with ID:', id);
    return axios.get(`/api/v1/customers/${id}`).then(response => {
      console.log('Customer API response:', response.data);
      return response;
    }).catch(error => {
      console.error('Error fetching customer:', error);
      throw error;
    });
  },
  
  updateCustomer(id, customerData) {
    console.log('Updating customer with ID:', id, 'Data:', customerData);
    return axios.put(`/api/v1/customers/${id}`, { customer: customerData });
  }
};