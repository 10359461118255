// Import your stores here
import { useAuthStore } from '../store/auth'

export function initializeStores() {
  // Initialize your stores here
  const authStore = useAuthStore()
  
  // Ensure authentication state is loaded from the backend
  authStore.fetchCurrentUser()
  
  // Return any stores that need to be accessed globally
  return {
    authStore
  }
}