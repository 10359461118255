<template>
  <div class="flex justify-center items-center min-h-screen py-8 bg-gray-50">
    <div v-if="loading" class="text-center p-10 bg-white rounded-lg shadow-lg max-w-md w-full border border-gray-200">
      <div class="text-[#0e1c5c] text-4xl font-extrabold tracking-widest mb-4" style="letter-spacing: 0.1em; font-family: Arial, sans-serif;">ALLDOQ</div>
      <ProgressSpinner style="width:60px;height:60px" strokeWidth="4" class="custom-spinner" />
      <p class="mt-4 text-lg text-blue-900">Loading your signup form...</p>
      <p class="text-sm text-gray-600 mt-2">Please wait while we retrieve your information</p>
    </div>
    
    <div v-else-if="error" class="bg-white p-8 rounded-lg shadow-lg max-w-md w-full text-center border border-gray-200">
      <div class="text-[#0e1c5c] text-4xl font-extrabold tracking-widest mb-4" style="letter-spacing: 0.1em; font-family: Arial, sans-serif;">ALLDOQ</div>
      <div class="bg-red-50 p-4 rounded-full inline-block mb-4">
        <i class="pi pi-exclamation-circle text-5xl text-red-500"></i>
      </div>
      <h2 class="text-2xl mb-4 text-blue-900">{{ errorTitle }}</h2>
      <div class="bg-gray-50 p-4 rounded-lg mb-6 border-l-4 border-red-500">
        <p class="text-gray-700">{{ errorMessage }}</p>
      </div>
      <Button 
        label="Back to Login" 
        icon="pi pi-arrow-left" 
        class="bg-blue-900 hover:bg-blue-800 text-white border-blue-900 px-6 py-2 rounded-full"
        @click="router.push('/login')" 
      />
    </div>
    
    <div v-else class="bg-white p-10 rounded-lg shadow-xl max-w-3xl w-full border border-gray-200 relative overflow-hidden">
      <!-- Decorative shapes in background -->
      <div class="absolute top-0 right-0 w-32 h-32 bg-blue-100 rounded-full -mr-16 -mt-16 opacity-50"></div>
      <div class="absolute bottom-0 left-0 w-24 h-24 bg-blue-100 rounded-full -ml-12 -mb-12 opacity-50"></div>
      
      <!-- Logo and header -->
      <div class="text-center mb-8 relative">
        <div class="text-[#0605f6] text-4xl font-extrabold tracking-widest mb-4" style="letter-spacing: 0.1em; font-family: Arial, sans-serif;">ALLDOQ</div>
        <h1 class="text-3xl text-blue-900">Setup your workspace</h1>
        <div class="h-1 w-24 bg-blue-600 mx-auto mt-4 rounded-full"></div>
      </div>
      
      <!-- Hero image -->
      <div class="flex justify-center mb-8">
        <img src="/images/adq-work-hero.png" alt="ALLDOQ Workspace" class="h-40 object-contain" />
      </div>
      
      <div class="relative mb-8">
        <div class="flex items-center justify-center">
          <div class="bg-blue-900 rounded-full w-10 h-10 flex items-center justify-center text-white font-bold shadow-md">1</div>
          <h2 class="text-xl font-medium ml-3 text-blue-900">Enter your details</h2>
        </div>
        
        <div class="w-full h-0.5 bg-gradient-to-r from-blue-100 via-blue-500 to-blue-100 my-4"></div>
      </div>
      
      <form @submit.prevent="handleSubmit" class="p-fluid">
        <div class="p-4 bg-white rounded-lg shadow-sm border border-gray-200 mb-6">
          <div class="flex flex-wrap gap-4 mb-4">
            <div class="field flex-1 min-w-[150px]">
              <label for="title" class="block mb-2 text-gray-700 font-medium">
                Title <span class="text-red-500">*</span>
              </label>
              <Dropdown
                id="title"
                v-model="userData.title"
                :options="titleOptions"
                placeholder="Select title"
                class="w-full border border-gray-300 rounded-md"
                :class="{'p-invalid': v$.title.$invalid && v$.title.$dirty}"
                @blur="v$.title.$touch()"
              />
              <small v-if="v$.title.$error" class="p-error">This field {{ v$.title.$errors[0].$message }}</small>
            </div>
            
            <div class="field flex-1 min-w-[200px]">
              <label for="firstName" class="block mb-2 text-gray-700 font-medium">
                First name <span class="text-red-500">*</span>
              </label>
              <InputText 
                id="firstName" 
                v-model="userData.firstName" 
                :class="{'p-invalid': v$.firstName.$invalid && v$.firstName.$dirty}"
                @blur="v$.firstName.$touch()"
              />
              <small v-if="v$.firstName.$error" class="p-error">This field {{ v$.firstName.$errors[0].$message }}</small>
            </div>
            
            <div class="field flex-1 min-w-[200px]">
              <label for="lastName" class="block mb-2 text-gray-700 font-medium">
                Last name <span class="text-red-500">*</span>
              </label>
              <InputText 
                id="lastName" 
                v-model="userData.lastName" 
                :class="{'p-invalid': v$.lastName.$invalid && v$.lastName.$dirty}"
                @blur="v$.lastName.$touch()"
              />
              <small v-if="v$.lastName.$error" class="p-error">This field {{ v$.lastName.$errors[0].$message }}</small>
            </div>
          </div>
          
          <div class="field mb-4">
            <label for="email" class="block mb-2 text-gray-700 font-medium">
              Email <span class="text-red-500">*</span>
            </label>
            <div class="flex items-center">
              <InputText id="email"  :model-value="signupInfo.email" disabled class="w-full" />
              <div class="ml-2 text-green-600" title="Email verified">
                <i class="pi pi-check-circle"></i>
              </div>
            </div>
            <small class="text-gray-500 italic">Email address cannot be changed</small>
          </div>
          
          <div class="field mb-4">
            <label for="phoneNumber" class="block mb-2 text-gray-700 font-medium">
              Mobile number <span class="text-red-500">*</span>
            </label>
            <div class="mb-1">
              <small class="text-blue-700 bg-blue-50 px-2 py-1 rounded-md inline-block">
                <i class="pi pi-info-circle mr-1"></i>
                Please note this has to be a MOBILE - not a landline number
              </small>
            </div>
            <InputText 
              id="phoneNumber" 
              v-model="userData.phoneNumber" 
              :class="{'p-invalid': v$.phoneNumber.$invalid && v$.phoneNumber.$dirty}"
              @blur="v$.phoneNumber.$touch()"
              placeholder="+44 7123 456789"
            />
            <small v-if="v$.phoneNumber.$error" class="p-error">This field {{ v$.phoneNumber.$errors[0].$message }}</small>
          </div>
          
          <div class="field mb-2">
            <label for="jobTitle" class="block mb-2 text-gray-700 font-medium">
              Job title <span class="text-red-500">*</span>
            </label>
            <InputText 
              id="jobTitle" 
              v-model="userData.jobTitle" 
              :class="{'p-invalid': v$.jobTitle.$invalid && v$.jobTitle.$dirty}"
              @blur="v$.jobTitle.$touch()"
              placeholder="e.g. Consultant Surgeon"
            />
            <small v-if="v$.jobTitle.$error" class="p-error">This field {{ v$.jobTitle.$errors[0].$message }}</small>
          </div>
        </div>
        
        <div class="relative mt-16 mb-8">
          <div class="absolute left-0 right-0 h-20 -top-10">
            <div class="h-full w-full bg-blue-50 transform -skew-y-2"></div>
          </div>
          
          <div class="relative">
            <div class="flex items-center justify-center">
              <div class="bg-blue-900 rounded-full w-10 h-10 flex items-center justify-center text-white font-bold shadow-md">2</div>
              <h2 class="text-xl font-medium ml-3 text-blue-900">The final step</h2>
            </div>
            
            <div class="w-full h-0.5 bg-gradient-to-r from-blue-100 via-blue-500 to-blue-100 my-4"></div>
            
            <p class="mb-6 text-gray-700 bg-blue-50 p-4 rounded-lg border-l-4 border-blue-500 shadow-sm">
              To enable us to set up your account, we need to create your unique Alldoq domain. Please enter the following details:
            </p>
          </div>
        </div>
        
        <div class="field mb-6 p-4 bg-white rounded-lg shadow-sm border border-gray-200">
          <label for="businessName" class="block mb-2 text-gray-700 font-medium">
            Business or company name of your medico-legal practice:
          </label>
          <p class="text-sm text-gray-600 mb-2">This is the name that will appear on your invoices and medico-legal reports. You can change this in account settings once your account is set up.</p>
          <InputText 
            id="businessName" 
            v-model="userData.businessName" 
            :class="{'p-invalid': v$.businessName.$invalid && v$.businessName.$dirty}"
            @blur="v$.businessName.$touch()"
            placeholder="Company Name"
          />
          <small v-if="v$.businessName.$error" class="p-error">This field {{ v$.businessName.$errors[0].$message }}</small>
        </div>
        
        <div class="field mb-8 p-4 bg-white rounded-lg shadow-sm border border-gray-200">
          <label for="domainName" class="block mb-2 text-gray-700 font-medium">
            Choose a domain name for your Alldoq workspace:
          </label>
          <p class="text-sm text-gray-600 mb-2">For example, surname, business-name or your initials</p>
          <p class="text-sm text-gray-600 mb-2">Please only include alphanumeric characters and a hyphen if desired. Do not include spaces, punctuation or special characters.</p>
          <p class="text-sm text-gray-600 mb-4">We recommend something short and memorable. Your domain name will be visible to instructing parties when you share through Alldoq.</p>
          <div class="flex items-center">
            <div class="flex-grow relative">
              <InputText 
                id="domainName" 
                v-model="userData.domainName" 
                :class="{'p-invalid': v$.domainName.$invalid && v$.domainName.$dirty}"
                @blur="handleDomainBlur"
                @input="handleDomainInput"
                placeholder="yoursubdomain"
              />
              <small v-if="v$.domainName.$error" class="p-error">This field {{ v$.domainName.$errors[0].$message }}</small>
            </div>
          </div>
          <div class="mt-3 text-blue-700 font-medium flex items-center bg-blue-50 p-3 rounded-lg">
            <i class="pi pi-globe mr-2 text-xl"></i>
            Your address will be: <span class="ml-1 font-bold">{{ formattedDomain }}</span>
          </div>
        </div>
        
        <div class="flex justify-center mt-8 mb-4">
          <Button 
            type="submit"
            label="Generate my Alldoq domain" 
            icon="pi pi-check"
            class="w-2/3 mx-auto bg-blue-600 hover:bg-blue-700 text-white border-blue-600 text-lg p-3 rounded-full shadow-lg"
            :loading="submitting"
            :disabled="submitting || v$.$invalid"
          />
        </div>
      </form>
    </div>
  </div>
</template>

<script setup>
import { ref, reactive, onMounted, computed } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useToast } from 'vue-toastification'
import signupLinkService from '../../utils/signupLinkService'
import { useVuelidate } from '@vuelidate/core'
import { required as baseRequired, minLength as baseMinLength, helpers } from '@vuelidate/validators'

// Default domain from ENV, fallback to alldoq.com
const BASE_DOMAIN = 'alldoq.com'

// Custom validators with custom messages
const required = helpers.withMessage('cannot be blank', baseRequired)
const minLength = (length) => helpers.withMessage(
  ({ $params }) => `must be at least ${$params.min} characters`, 
  baseMinLength(length)
)

// PrimeVue components
import Button from 'primevue/button'
import InputText from 'primevue/inputtext'
import ProgressSpinner from 'primevue/progressspinner'
import Dropdown from 'primevue/dropdown'

const route = useRoute()
const router = useRouter()
const toast = useToast()

const loading = ref(true)
const submitting = ref(false)
const error = ref(false)
const errorTitle = ref('')
const errorMessage = ref('')
const signupInfo = ref({
  token: '',
  email: '',
  expires_at: null
})

const titleOptions = [
  'Dr',
  'Prof',
  'Mr',
  'Mrs',
  'Ms',
  'Miss',
  'Other'
]

const userData = reactive({
  title: '',
  firstName: '',
  lastName: '',
  phoneNumber: '',
  jobTitle: '',
  businessName: '',
  domainName: ''
})

// Validation rules
const rules = {
  title: { required },
  firstName: { required, minLength: minLength(2) },
  lastName: { required, minLength: minLength(2) },
  phoneNumber: { 
    required,
    minLength: helpers.withMessage('must be a valid mobile number with at least 10 digits', baseMinLength(10))
  },
  jobTitle: { required },
  businessName: { required },
  domainName: { 
    required,
    validDomain: helpers.withMessage(
      'only allows letters, numbers, hyphens, and dots for domain format',
      (value) => {
        // For full domain format (e.g., example.alldoq.com)
        if (value.includes('.')) {
          return /^[a-zA-Z0-9][a-zA-Z0-9-]*(\.[a-zA-Z0-9][a-zA-Z0-9-]*)+$/.test(value);
        }
        // For subdomain only (e.g., example)
        return /^[a-zA-Z0-9-]+$/.test(value);
      }
    )
  }
}

const v$ = useVuelidate(rules, userData)

// Get the token from the URL
const token = computed(() => route.params.token)

// Format the full domain for display
const formattedDomain = computed(() => {
  if (!userData.domainName) return `yoursubdomain.${BASE_DOMAIN}`
  
  // If the domain already has a dot (possibly a full domain entered)
  if (userData.domainName.includes('.')) {
    // If it's a full domain, just display it as is
    return userData.domainName
  }
  
  return `${userData.domainName}.${BASE_DOMAIN}`
})

// Handle domain blur event to clean up input
const handleDomainBlur = () => {
  v$.value.domainName.$touch()
  if (!userData.domainName) return
  
  // For display purposes, keep the full domain if entered
  // but extract subdomain for backend processing when form is submitted
  
  // Clean the input - convert to lowercase, trim spaces
  userData.domainName = userData.domainName.toLowerCase().trim()
}

// Handle domain input event
const handleDomainInput = () => {
  // Remove any spaces immediately while typing
  if (userData.domainName && userData.domainName.includes(' ')) {
    userData.domainName = userData.domainName.replace(/\s+/g, '')
  }
}

// Fetch signup information
const fetchSignupInfo = async () => {
  loading.value = true
  error.value = false
  
  try {
    const response = await signupLinkService.getSignupForm(token.value)
    const data = response.data
    
    if (data.status === 'success') {
      signupInfo.value = data.signup_link
    } else {
      setError('Invalid Link', data.message || 'The signup link is invalid or has expired.')
    }
  } catch (err) {
    console.error('Error fetching signup info:', err)
    
    if (err.response && err.response.status === 404) {
      setError('Link Not Found', 'The signup link you are trying to access does not exist.')
    } else if (err.response && err.response.status === 410) {
      setError('Link Expired', 'This signup link has expired or has already been used.')
    } else {
      setError('Error', 'An error occurred while loading the signup form. Please try again later.')
    }
  } finally {
    loading.value = false
  }
}

// Set error state
const setError = (title, message) => {
  error.value = true
  errorTitle.value = title
  errorMessage.value = message
}

// Handle form submission
const handleSubmit = async () => {
  const isValid = await v$.value.$validate()
  if (!isValid) return
  
  submitting.value = true
  
  try {
    // Extract just the subdomain if a full domain was entered
    let domainToSubmit = userData.domainName;
    if (domainToSubmit.includes('.')) {
      domainToSubmit = domainToSubmit.split('.')[0];
    }
    
    const response = await signupLinkService.completeSignup(token.value, {
      title: userData.title,
      first_name: userData.firstName,
      last_name: userData.lastName,
      phone_number: userData.phoneNumber,
      job_title: userData.jobTitle,
      business_name: userData.businessName,
      domain_name: domainToSubmit
    })
    
    const data = response.data
    
    if (data.status === 'success') {
      toast.success('Registration completed successfully! Check your email for your ALLDOQ account information.')
      router.push('/login')
    } else {
      toast.error(data.message || 'Failed to complete registration.')
    }
  } catch (err) {
    console.error('Error completing signup:', err)
    
    if (err.response && err.response.data && err.response.data.errors) {
      // Display validation errors
      toast.error(err.response.data.errors.join(', '))
    } else {
      toast.error('An error occurred while completing your registration. Please try again later.')
    }
  } finally {
    submitting.value = false
  }
}

onMounted(() => {
  if (!token.value) {
    setError('Invalid Link', 'No signup token provided.')
    loading.value = false
    return
  }
  
  fetchSignupInfo()
})
</script>

<style scoped>
.p-inputtext,
.p-dropdown {
  border: 1px solid #ced4da !important;
  border-radius: 4px !important;
  padding: 0.75rem !important;
  width: 100% !important;
  transition: border-color 0.2s, box-shadow 0.2s !important;
  outline: none !important;
}

.p-inputtext:hover,
.p-dropdown:hover {
  border-color: #2563eb !important;
}

.p-inputtext:focus,
.p-dropdown:focus {
  border-color: #2563eb !important;
  box-shadow: 0 0 0 0.2rem rgba(37, 99, 235, 0.25) !important;
  outline: 0 none !important;
}

.p-dropdown-panel {
  border: 1px solid #ced4da !important;
}

.p-dropdown-item {
  padding: 0.75rem 1rem !important;
}

.p-invalid {
  border-color: #ef4444 !important;
}

.p-error {
  color: #ef4444 !important;
  margin-top: 0.25rem !important;
  font-size: 0.875rem !important;
  display: block !important;
  font-style: italic !important;
}

.p-inputtext:disabled {
  background-color: #f3f4f6 !important;
  cursor: not-allowed !important;
}

.custom-spinner .p-progress-spinner-circle {
  stroke: #1e40af !important;
  animation-duration: 1.5s !important;
}

/* Animated gradient border for the domain input section */
@keyframes border-pulse {
  0% { border-color: #bfdbfe; }
  50% { border-color: #3b82f6; }
  100% { border-color: #bfdbfe; }
}

.field:has(#domainName) {
  animation: border-pulse 3s infinite;
  transition: all 0.3s;
}
</style>