import axios from 'axios';

// Get CSRF token from the meta tag
const getCsrfToken = () => {
  return document.querySelector('meta[name="csrf-token"]')?.getAttribute('content');
};

// Configure axios with default headers
axios.defaults.headers.common['X-CSRF-Token'] = getCsrfToken();
axios.defaults.headers.common['Content-Type'] = 'application/json';

export const userService = {
  // Get all users
  getUsers: async () => {
    try {
      const response = await axios.get('/api/v1/users');
      return response.data;
    } catch (error) {
      console.error('Error fetching users:', error);
      throw error;
    }
  },
  
  // Get user by ID
  getUser: async (id) => {
    try {
      const response = await axios.get(`/api/v1/users/${id}`);
      return response.data;
    } catch (error) {
      console.error(`Error fetching user ${id}:`, error);
      throw error;
    }
  },
  
  // Create a new user
  createUser: async (userData) => {
    try {
      const response = await axios.post('/api/v1/users', { user: userData });
      return response.data;
    } catch (error) {
      console.error('Error creating user:', error);
      throw error;
    }
  },
  
  // Update a user
  updateUser: async (id, userData) => {
    try {
      const response = await axios.patch(`/api/v1/users/${id}`, { user: userData });
      return response.data;
    } catch (error) {
      console.error(`Error updating user ${id}:`, error);
      throw error;
    }
  },
  
  // Delete a user
  deleteUser: async (id) => {
    try {
      const response = await axios.delete(`/api/v1/users/${id}`);
      return response.data;
    } catch (error) {
      console.error(`Error deleting user ${id}:`, error);
      throw error;
    }
  }
};