import { createApp } from 'vue';
import { createPinia } from 'pinia'
import Lara from '@primevue/themes/lara';
import PrimeVue from 'primevue/config';
import Tooltip from 'primevue/tooltip';
import Ripple from 'primevue/ripple';
import ConfirmationService from 'primevue/confirmationservice';
import ToastService from 'primevue/toastservice';
import { initializeStores } from '../stores/storeInitializer';
import Toast from 'vue-toastification'
import 'vue-toastification/dist/index.css'
import '../assets/css/tooltip.css'
import mitt from 'mitt'

export default function () {
  const emitter = mitt();
  const primeInitVue = (rootComponent, selector, props = {}) => {

    const app = createApp(rootComponent);
    
    // Initialize Vue router
    if (props.router) {
      app.use(props.router);
    }
    
    app.directive('tooltip', Tooltip);
    app.directive('ripple', Ripple);
    app.use(createPinia());
    app.use(ConfirmationService);
    app.use(ToastService);
    app.use(Toast, {
      position: 'top-right',
      timeout: 5000,
      closeOnClick: true
    });
    app.use(PrimeVue, {
      theme: {
        preset: Lara,
        options: {
          prefix: 'p',
          darkModeSelector: 'system',
          cssLayer: {
            name: 'primevue',
            order: 'tailwind-base, primevue'
          }
        }
      }
    });
          
    if (document.querySelector(selector)) {
      app.config.globalProperties.emitter = emitter;
      initializeStores();
      app.mount(selector);
    }
  }

  return {
    primeInitVue
  }
}