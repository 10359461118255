import App from '../App.vue'
import router from '../router'
import '../assets/css/main.css'
import 'primeicons/primeicons.css'  // Add PrimeIcons CSS
import axios from 'axios'
import primeInitFactory from '../utils/primeInit'

// Add CSRF token to all requests
const token = document.querySelector('meta[name="csrf-token"]')?.getAttribute('content')
if (token) {
  axios.defaults.headers.common['X-CSRF-Token'] = token
}

document.addEventListener('DOMContentLoaded', () => {
  // Create a Vue app instance with PrimeVue
  const { primeInitVue } = primeInitFactory()
  
  // Initialize the app with router and props
  primeInitVue(App, '#app', { router: router })
})