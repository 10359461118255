<template>
  <MainLayout>
    <template #header>
      <h1 class="text-2xl font-bold mb-4">Customers</h1>
    </template>

    <DataTable 
      :value="filteredCustomers" 
      :paginator="true" 
      :rows="10"
      :rowsPerPageOptions="[5, 10, 20, 50]"
      dataKey="id"
      showFilterRow
      filterDisplay="row"
      responsiveLayout="scroll"
      v-model:expandedRows="expandedRows"
      class="p-datatable-customers"
      :loading="loading"
      @rowClick="onRowClick"
    >
      <template #empty>
        <div class="text-center">No customers found</div>
      </template>
      <template #loading>
        <div class="text-center">Loading customers...</div>
      </template>

      <!-- Expansion template for AllDoq instance details -->
      <template #expansion="slotProps">
        <AllDoqInstanceDetails 
          :customerId="slotProps.data.id" 
          :customerName="`${slotProps.data.first_name} ${slotProps.data.last_name}`" 
          :instanceUrl="slotProps.data.alldoq_instance ? `https://${slotProps.data.alldoq_instance.subdomain}.alldoq.com` : ''"
          :instanceStatus="slotProps.data.alldoq_instance ? slotProps.data.alldoq_instance.status : 'No Instance'"
        />
      </template>

      <!-- Table header -->
      <Column field="business_name" header="Business" :sortable="true">
        <template #filter>
          <InputText 
            v-model="filters.business_name" 
            type="text" 
            class="p-column-filter" 
            placeholder="Filter business..." 
          />
        </template>
      </Column>
      <Column field="full_name" header="Contact" :sortable="true">
        <template #filter>
          <InputText 
            v-model="filters.full_name" 
            type="text" 
            class="p-column-filter" 
            placeholder="Filter name..." 
          />
        </template>
        <template #body="slotProps">
          <router-link 
            :to="{ name: 'CustomerProfile', params: { id: slotProps.data.id }, query: { tab: 'alldoq' } }" 
            class="text-blue-600 hover:underline"
            @click.stop
          >
            {{ slotProps.data.first_name }} {{ slotProps.data.last_name }}
          </router-link>
          <div class="text-sm text-gray-500">{{ slotProps.data.job_title }}</div>
        </template>
      </Column>
      <Column field="email" header="Email" :sortable="true">
        <template #filter>
          <InputText 
            v-model="filters.email" 
            type="text" 
            class="p-column-filter" 
            placeholder="Filter email..." 
          />
        </template>
      </Column>
      <Column field="alldoq_instance.status" header="Instance Status" :sortable="true">
        <template #filter>
          <Dropdown 
            v-model="filters.instance_status" 
            :options="instanceStatusOptions" 
            optionLabel="label" 
            optionValue="value"
            placeholder="Select Status" 
            class="p-column-filter"
          />
        </template>
        <template #body="slotProps">
          <Tag v-if="slotProps.data.alldoq_instance" :severity="getInstanceSeverity(slotProps.data.alldoq_instance.status)" :value="slotProps.data.alldoq_instance.status" />
          <Tag v-else severity="danger" value="No Instance" />
        </template>
      </Column>
      <Column :expander="true" headerStyle="width: 3rem" />
    </DataTable>
  </MainLayout>
</template>

<script setup>
import { ref, onMounted, watch, computed } from 'vue';
import { useRouter } from 'vue-router';
import { useToast } from 'primevue/usetoast';
import { useAuthStore } from '../store/auth';
import customerService from '../utils/customerService';
import MainLayout from '../components/MainLayout.vue';
import DataTable from 'primevue/datatable'
import Column from 'primevue/column'
import InputText from 'primevue/inputtext'
import Dropdown from 'primevue/dropdown';
import Tag from 'primevue/tag';
import AllDoqInstanceDetails from '../components/AllDoqInstanceDetails.vue';

const router = useRouter();
const toast = useToast();
const authStore = useAuthStore();

// Data
const customers = ref([]);
const loading = ref(true);
const expandedRows = ref([]);
const filters = ref({
  business_name: '',
  full_name: '',
  email: '',
  instance_status: null
});

const instanceStatusOptions = ref([
  { label: 'All', value: null },
  { label: 'Active', value: 'active' },
  { label: 'Inactive', value: 'inactive' },
  { label: 'Pending', value: 'pending' },
  { label: 'No Instance', value: 'no_instance' }
]);

// Handle row click to expand/collapse
const onRowClick = (event) => {
  const rowData = event.data;
  const isExpanded = expandedRows.value.some(row => row.id === rowData.id);
  
  if (isExpanded) {
    // Collapse row if already expanded
    expandedRows.value = expandedRows.value.filter(row => row.id !== rowData.id);
  } else {
    // Expand clicked row
    expandedRows.value = [...expandedRows.value, rowData];
  }
};

// Computed filtered customers
const filteredCustomers = computed(() => {
  if (!customers.value.length) return [];
  
  return customers.value.filter(customer => {
    // Business name filter
    if (filters.value.business_name && 
        !customer.business_name?.toLowerCase().includes(filters.value.business_name.toLowerCase())) {
      return false;
    }
    
    // Full name filter
    if (filters.value.full_name && 
        !customer.full_name?.toLowerCase().includes(filters.value.full_name.toLowerCase())) {
      return false;
    }
    
    // Email filter
    if (filters.value.email && 
        !customer.email?.toLowerCase().includes(filters.value.email.toLowerCase())) {
      return false;
    }
    
    // Instance status filter
    if (filters.value.instance_status) {
      if (filters.value.instance_status === 'no_instance') {
        // Special case for "No Instance"
        return !customer.alldoq_instance;
      } else {
        // Check for matching status
        return customer.alldoq_instance?.status === filters.value.instance_status;
      }
    }
    
    return true;
  });
});

// Methods
const loadCustomers = async () => {
  loading.value = true;
  try {
    const response = await customerService.getCustomers();
    customers.value = response.data.map(customer => ({
      ...customer,
      full_name: `${customer.first_name || ''} ${customer.last_name || ''}`.trim()
    }));
  } catch (error) {
    console.error('Error loading customers:', error);
    toast.add({
      severity: 'error',
      summary: 'Error',
      detail: 'Failed to load customers',
      life: 3000
    });
  } finally {
    loading.value = false;
  }
};

const getInstanceSeverity = (status) => {
  if (!status) return 'danger';
  
  switch (status.toLowerCase()) {
    case 'active':
      return 'success';
    case 'pending':
      return 'warning';
    case 'inactive':
      return 'danger';
    default:
      return 'info';
  }
};

// Lifecycle
onMounted(async () => {
  if (authStore.isAuthenticated) {
    await loadCustomers();
  } else {
    router.push({ name: 'Login' });
  }
});

// Watch for auth state changes
watch(
  () => authStore.isAuthenticated,
  (isAuthenticated) => {
    if (isAuthenticated) {
      loadCustomers();
    }
  }
);
</script>

<style scoped>
.p-datatable-customers .p-datatable-tbody > tr > td {
  padding: 0.75rem 1rem;
}

/* Improve filter inputs spacing and height */
:deep(.p-column-filter) {
  width: 100%;
  min-height: 2.5rem;
  padding: 0.5rem;
  margin-top: 0.5rem;
}

:deep(.p-column-filter-row) {
  padding: 0.5rem;
}

:deep(.p-column-filter-row .p-column-filter-element) {
  width: 100%;
}

:deep(.p-dropdown) {
  width: 100%;
}

:deep(.p-dropdown-label) {
  padding: 0.5rem;
}

:deep(.p-column-header-content) {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

/* Make table rows clickable */
:deep(.p-datatable-tbody > tr) {
  cursor: pointer;
}

:deep(.p-datatable-tbody > tr:hover) {
  background-color: #f8fafc;
}
</style>