<template>
  <div class="bg-white p-6 rounded-lg shadow-md max-w-md mx-auto">
    <div class="mb-6">
      <div class="flex flex-col items-center">
        <div class="relative inline-block">
          <h1 class="text-3xl font-bold" style="color: #0605f6;">ALLDOQ</h1>
          <span class="absolute right-0 text-sm text-gray-500" style="top: 30px;">ground control</span>
        </div>
      </div>
    </div>
    
    <p v-if="authStore.is2FAApp" class="mb-6 text-gray-700 text-center">
      Please enter the passcode from your authenticator app.
    </p>
    
    <div v-if="authStore.is2FASms" class="mb-6">
      <p class="mb-2 text-gray-700 text-center">
        Please enter the passcode sent to your phone number.
      </p>
      <div class="flex justify-center">
        <button 
          @click="requestSmsCode" 
          class="text-blue-500 hover:text-blue-700 text-sm mt-2"
          :disabled="authStore.loading || requestingCode"
        >
          {{ requestingCode ? 'Sending...' : 'Resend code' }}
        </button>
      </div>
      <p v-if="codeSent" class="text-green-500 text-sm mt-1 text-center">
        Verification code has been sent!
      </p>
    </div>
    
    <form @submit.prevent="verify">
      <div class="mb-6">
        <label for="otp_code" class="block text-gray-700 text-sm font-bold mb-2">Verification Code</label>
        <input 
          type="text" 
          id="otp_code" 
          v-model="otpCode" 
          required
          class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          placeholder="Enter 6-digit code"
          maxlength="6"
          pattern="[0-9]{6}"
          inputmode="numeric"
        />
      </div>
      
      <div class="flex items-center justify-between">
        <button 
          type="submit" 
          class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
          :disabled="authStore.loading"
        >
          {{ authStore.loading ? 'Verifying...' : 'Verify' }}
        </button>
        <button
          @click="cancelLogin"
          type="button"
          class="inline-block align-baseline font-bold text-sm text-red-500 hover:text-red-800"
        >
          Cancel
        </button>
      </div>
    </form>
  </div>
</template>

<script setup>
import { ref } from 'vue'
import { useRouter } from 'vue-router'
import { useAuthStore } from '../../store/auth'

const otpCode = ref('')
const router = useRouter()
const authStore = useAuthStore()
const requestingCode = ref(false)
const codeSent = ref(false)

// Always redirect to login if user directly navigates to this page without going through login flow
if (!authStore.isPending2FA) {
  router.push('/login')
}

const verify = async () => {
  try {
    const result = await authStore.verify2FA(otpCode.value)
    
    if (result.status === 'success') {
      router.push('/dashboard')
    }
  } catch (error) {
    // Error is handled in the store
  }
}

const requestSmsCode = async () => {
  requestingCode.value = true
  codeSent.value = false
  
  try {
    const result = await authStore.requestSmsCode()
    if (result.status === 'success') {
      codeSent.value = true
    }
  } catch (error) {
    // Error is handled in the store
  } finally {
    requestingCode.value = false
  }
}

const cancelLogin = () => {
  authStore.pending2FA = false
  authStore.pendingUserId = null
  authStore.twoFactorMethod = null
  router.push('/login')
}
</script>