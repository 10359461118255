import { defineStore } from 'pinia'
import axios from 'axios'

export const useAuthStore = defineStore('auth', {
  state: () => ({
    user: JSON.parse(localStorage.getItem('user')) || null,
    pending2FA: false, // Initialize as false and only set to true after login
    pendingUserId: null,
    twoFactorMethod: null,
    loading: false,
    error: null
  }),
  
  getters: {
    isAuthenticated: (state) => !!state.user,
    isPending2FA: (state) => state.pending2FA,
    currentUser: (state) => state.user,
    is2FASms: (state) => state.twoFactorMethod === 'sms',
    is2FAApp: (state) => state.twoFactorMethod === 'app',
  },
  
  actions: {
    async login(email, password) {
      this.loading = true
      this.error = null
      
      try {
        const response = await axios.post('/api/v1/login', { email, password })
        
        if (response.data.status === 'pending_2fa') {
          this.pending2FA = true
          this.pendingUserId = response.data.user_id
          this.twoFactorMethod = response.data.two_factor_method
          
          localStorage.setItem('pending2FA', 'true')
          localStorage.setItem('pendingUserId', response.data.user_id)
          localStorage.setItem('twoFactorMethod', response.data.two_factor_method)
        } else if (response.data.status === 'success') {
          this.user = response.data.user
          this.pending2FA = false
          this.pendingUserId = null
          this.twoFactorMethod = null
          
          localStorage.setItem('user', JSON.stringify(response.data.user))
          localStorage.removeItem('pending2FA')
          localStorage.removeItem('pendingUserId')
          localStorage.removeItem('twoFactorMethod')
        }
        
        return response.data
      } catch (error) {
        this.error = error.response?.data?.message || 'Failed to login'
        throw error
      } finally {
        this.loading = false
      }
    },
    
    async requestSmsCode() {
      this.loading = true
      this.error = null
      
      try {
        const response = await axios.post('/api/v1/request_sms_code', { 
          user_id: this.pendingUserId
        })
        
        return response.data
      } catch (error) {
        this.error = error.response?.data?.message || 'Failed to send SMS code'
        throw error
      } finally {
        this.loading = false
      }
    },
    
    async verify2FA(otp_code) {
      this.loading = true
      this.error = null
      
      try {
        const response = await axios.post('/api/v1/verify_2fa', { 
          user_id: this.pendingUserId,
          otp_code 
        })
        
        if (response.data.status === 'success') {
          this.user = response.data.user
          this.pending2FA = false
          this.pendingUserId = null
          this.twoFactorMethod = null
          
          localStorage.setItem('user', JSON.stringify(response.data.user))
          localStorage.removeItem('pending2FA')
          localStorage.removeItem('pendingUserId')
          localStorage.removeItem('twoFactorMethod')
        }
        
        return response.data
      } catch (error) {
        this.error = error.response?.data?.message || 'Failed to verify 2FA code'
        throw error
      } finally {
        this.loading = false
      }
    },
    
    async register(userData) {
      this.loading = true
      this.error = null
      
      try {
        const response = await axios.post('/api/v1/users', { user: userData })
        
        // We don't automatically set the user when registering, as the system now
        // generates a password and sends it by email
        return response.data
      } catch (error) {
        this.error = error.response?.data?.errors || ['Registration failed']
        throw error
      } finally {
        this.loading = false
      }
    },
    
    async logout() {
      this.loading = true
      
      try {
        await axios.delete('/api/v1/logout')
        this.user = null
        this.pending2FA = false
        this.pendingUserId = null
        this.twoFactorMethod = null
        
        // Clear all auth data from localStorage
        localStorage.removeItem('user')
        localStorage.removeItem('pending2FA')
        localStorage.removeItem('pendingUserId')
        localStorage.removeItem('twoFactorMethod')
      } catch (error) {
        console.error('Logout error:', error)
      } finally {
        this.loading = false
      }
    },
    
    async setup2FA() {
      this.loading = true
      this.error = null
      
      try {
        const response = await axios.get('/api/v1/setup_2fa')
        return response.data
      } catch (error) {
        this.error = 'Failed to setup 2FA'
        throw error
      } finally {
        this.loading = false
      }
    },
    
    async enable2FA(otp_code) {
      this.loading = true
      this.error = null
      
      try {
        const response = await axios.post('/api/v1/enable_2fa', { otp_code })
        return response.data
      } catch (error) {
        this.error = error.response?.data?.message || 'Failed to enable 2FA'
        throw error
      } finally {
        this.loading = false
      }
    },
    
    async disable2FA() {
      this.loading = true
      this.error = null
      
      try {
        const response = await axios.post('/api/v1/disable_2fa')
        return response.data
      } catch (error) {
        this.error = 'Failed to disable 2FA'
        throw error
      } finally {
        this.loading = false
      }
    },
    
    async update2FAMethod(method) {
      this.loading = true
      this.error = null
      
      try {
        const response = await axios.post('/api/v1/update_2fa_method', { two_factor_method: method })
        return response.data
      } catch (error) {
        this.error = 'Failed to update 2FA method'
        throw error
      } finally {
        this.loading = false
      }
    },
    
    async fetchCurrentUser() {
      this.loading = true
      
      try {
        const response = await axios.get('/api/v1/users/show')
        this.user = response.data.user
        
        // Update localStorage with user data
        if (this.user) {
          localStorage.setItem('user', JSON.stringify(this.user))
        } else {
          localStorage.removeItem('user')
        }
        
        // Clear any possible stale 2FA state
        this.pending2FA = false
        this.pendingUserId = null
        this.twoFactorMethod = null
        localStorage.removeItem('pending2FA')
        localStorage.removeItem('pendingUserId')
        localStorage.removeItem('twoFactorMethod')
        
        return this.user
      } catch (error) {
        this.user = null
        localStorage.removeItem('user')
        console.error('Error fetching current user:', error)
      } finally {
        this.loading = false
      }
    }
  }
})