<template>
  <header class="bg-white shadow flex items-center justify-between px-6 py-4">
    <!-- Left side - empty or could have page title -->
    <div>
      <slot name="title"></slot>
    </div>
    
    <!-- Right side - user dropdown -->
    <div class="relative">
      <div v-if="isAuthenticated" class="flex items-center cursor-pointer" @click="toggleDropdown">
        <div class="w-10 h-10 bg-blue-500 rounded-full flex items-center justify-center text-white text-sm font-semibold">
          {{ userInitials }}
        </div>
        <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 ml-1 text-gray-600" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7" />
        </svg>
      </div>
      <div v-else class="flex space-x-4">
        <router-link to="/login" class="text-gray-700 hover:text-gray-900">Login</router-link>
        <router-link to="/register" class="text-gray-700 hover:text-gray-900">Register</router-link>
      </div>
      
      <!-- Dropdown Menu -->
      <div v-if="dropdownOpen && isAuthenticated" class="absolute right-0 mt-2 w-56 bg-white rounded-md shadow-lg py-1 z-10 border border-gray-200">
        <div class="px-4 py-3 border-b border-gray-200">
          <p class="text-sm font-medium text-gray-900">{{ userName }}</p>
        </div>
        <router-link to="/profile" class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">
          <i class="fas fa-user-circle mr-2"></i> Profile
        </router-link>
        <router-link to="/setup-2fa" class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">
          <i class="fas fa-shield-alt mr-2"></i> Setup 2FA
        </router-link>
        <router-link to="/change-password" class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">
          <i class="fas fa-key mr-2"></i> Change Password
        </router-link>
        <div class="border-t border-gray-200 mt-1"></div>
        <button @click="handleLogout" class="block w-full text-left px-4 py-2 text-sm text-red-600 hover:bg-gray-100">
          <i class="fas fa-sign-out-alt mr-2"></i> Logout
        </button>
      </div>
    </div>
  </header>
</template>

<script setup>
import { ref, onMounted, onBeforeUnmount } from 'vue'

const props = defineProps({
  isAuthenticated: {
    type: Boolean,
    default: false
  },
  userName: {
    type: String,
    default: 'User'
  },
  userInitials: {
    type: String,
    default: 'U'
  }
})

const emit = defineEmits(['logout'])

const dropdownOpen = ref(false)

const toggleDropdown = () => {
  dropdownOpen.value = !dropdownOpen.value
}

const handleLogout = () => {
  emit('logout')
  dropdownOpen.value = false
}

// Close dropdown when clicking outside
const handleClickOutside = (event) => {
  const dropdown = document.querySelector('.relative')
  if (dropdown && !dropdown.contains(event.target) && dropdownOpen.value) {
    dropdownOpen.value = false
  }
}

onMounted(() => {
  document.addEventListener('click', handleClickOutside)
})

onBeforeUnmount(() => {
  document.removeEventListener('click', handleClickOutside)
})
</script> 