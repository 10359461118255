import { createRouter, createWebHistory } from 'vue-router'
import { useAuthStore } from '../store/auth'

// Import views
import HomeView from '../views/Home.vue'
import LoginView from '../views/auth/Login.vue'
import RegisterView from '../views/auth/Register.vue'
import Verify2FAView from '../views/auth/Verify2FA.vue'
import ProfileView from '../views/auth/Profile.vue'
import Setup2FAView from '../views/auth/Setup2FA.vue'
import ChangePasswordView from '../views/auth/ChangePassword.vue'
import UsersView from '../views/UsersView.vue'
import DashboardView from '../views/Dashboard.vue'
import SignupLinksView from '../views/SignupLinksView.vue'
import CustomersView from '../views/CustomersView.vue'
import CustomerProfileView from '../views/CustomerProfileView.vue'
import CompleteSignupView from '../views/auth/CompleteSignup.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: HomeView,
    beforeEnter: (to, from, next) => {
      const authStore = useAuthStore()
      if (authStore.isAuthenticated) {
        next({ name: 'Dashboard' })
      } else {
        next({ name: 'Login' })
      }
    }
  },
  {
    path: '/login',
    name: 'Login',
    component: LoginView,
    meta: { guestOnly: true }
  },
  {
    path: '/register',
    name: 'Register',
    component: RegisterView,
    meta: { requiresAuth: true }
  },
  {
    path: '/verify-2fa',
    name: 'Verify2FA',
    component: Verify2FAView,
    meta: { guestOnly: true }
  },
  {
    path: '/profile',
    name: 'Profile',
    component: ProfileView,
    meta: { requiresAuth: true }
  },
  {
    path: '/setup-2fa',
    name: 'Setup2FA',
    component: Setup2FAView,
    meta: { requiresAuth: true }
  },
  {
    path: '/change-password',
    name: 'ChangePassword',
    component: ChangePasswordView,
    meta: { requiresAuth: true }
  },
  {
    path: '/users',
    name: 'Users',
    component: UsersView,
    meta: { requiresAuth: true }
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: DashboardView,
    meta: { requiresAuth: true }
  },
  {
    path: '/signup-links',
    name: 'SignupLinks',
    component: SignupLinksView,
    meta: { requiresAuth: true }
  },
  {
    path: '/customers',
    name: 'Customers',
    component: CustomersView,
    meta: { requiresAuth: true }
  },
  {
    path: '/customers/:id',
    name: 'CustomerProfile',
    component: CustomerProfileView,
    meta: { requiresAuth: true }
  },
  {
    path: '/signup/:token',
    name: 'CompleteSignup',
    component: CompleteSignupView,
    meta: { public: true, guestOnly: true, noLayout: true }
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

router.beforeEach(async (to, from, next) => {
  const authStore = useAuthStore()
  
  // Special handling for CompleteSignup - skip auth check completely
  if (to.name === 'CompleteSignup' || to.path.startsWith('/signup/')) {
    return next()
  }
  
  // Check if user is already loaded, if not try to load
  if (!authStore.isAuthenticated && !authStore.loading) {
    try {
      await authStore.fetchCurrentUser()
    } catch (error) {
      // User is not authenticated
    }
  }
  
  // Allow public routes to pass through without auth check
  if (to.matched.some(record => record.meta.public)) {
    return next()
  }
  
  // Handle pending 2FA routes - only redirect if not going to login page
  if (authStore.isPending2FA && to.name !== 'Verify2FA' && to.name !== 'Login') {
    return next({ name: 'Verify2FA' })
  }
  
  // Handle auth-required routes
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!authStore.isAuthenticated) {
      return next({ name: 'Login' })
    }
  }
  
  // Handle guest-only routes
  if (to.matched.some(record => record.meta.guestOnly)) {
    if (authStore.isAuthenticated && to.name !== 'CompleteSignup') {
      return next({ name: 'Dashboard' })
    }
  }
  
  next()
})

export default router