<template>
  <main class="flex-1 overflow-auto bg-gray-100">
    <div class="container mx-auto px-6 py-4">
      <slot name="alerts"></slot>
      <slot></slot>
    </div>
  </main>
</template>

<script setup>
</script> 